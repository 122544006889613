import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Aboutlockyourland from "../components/Home/Aboutlockyourland";
import ExcitingPlaces from "../components/Home/ExcitingPlaces";
import Lockyourland from "../components/Home/Lockyourland";
import PropertyListing from "../components/Home/PropertyListing";

import SecureProperty from "../components/Home/SecureProperty";
import { getAllProperties } from "../features/property/propertySlice";
import { getAllLands } from "../features/land/landSlice";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { property: properties, isLoading } = useSelector(
    (state) => state.property
  );

  const { land: lands } = useSelector((state) => state.land);

  useEffect(() => {
    dispatch(getAllProperties());
    dispatch(getAllLands());
  }, []);

  return (
    <>
      <Lockyourland />
      <SecureProperty />
      <Aboutlockyourland />
      <PropertyListing isLoading={isLoading} properties={properties} />
      <ExcitingPlaces lands={lands} />
    </>
  );
};

export default Home;
