import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoginForm from "../components/Login/LoginForm";
import Navbar from "../components/Navbar";

const Login = () => {
  const location = useLocation();
  const [redirectFrom, setRedirectFrom] = useState();

  useEffect(() => {
    // Access the state from the location object
    const state = location.state;

    if (state) {
      const { redirectFrom } = state;
      setRedirectFrom(redirectFrom);
    }
  }, [location.state]);
  return (
    <>
      <Navbar />
      <LoginForm redirectFrom={redirectFrom} />
    </>
  );
};

export default Login;
