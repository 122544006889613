const maskString = (str, start, end) => {
  if (typeof str !== "string" || str.length === 0) return "";
  return (
    str.slice(0, start) +
    "*".repeat(Math.max(0, str.length - start - end)) +
    str.slice(-end)
  );
};

export const maskPhoneNumber = (phone) => {
  if (!phone || typeof phone !== "string") return "";
  return maskString(phone, 3, 2);
};

export const maskEmail = (email) => {
  if (!email || typeof email !== "string") return "";
  const atIndex = email.indexOf("@");
  if (atIndex === -1) return maskString(email, 2, 1);
  const username = email.slice(0, atIndex);
  const domain = email.slice(atIndex + 1);
  return `${maskString(username, 2, 1)}@${domain}`;
};

export const maskAddress = (address) => {
  if (!address || typeof address !== "string") return "";
  const parts = address.split(" ");
  if (parts.length > 1) {
    return `${parts[0]} ${maskString(parts.slice(1).join(" "), 0, 5)}`;
  }
  return maskString(address, 0, 5);
};
