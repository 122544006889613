import { Box, Button } from "@mui/material";
import React from "react";

const Pagination = ({
  currentPage,
  setCurrentPage,
  properties,
  indexOfLastUser,
}) => {
  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = indexOfLastUser >= properties?.length;

  return (
    <Box>
      <Box className="w-full flex items-center justify-between mt-5">
        {isFirstPage ? (
          <Button
            variant="contained"
            disableElevation
            onClick={handlePrev}
            size="small"
            color="success"
            disabled
          >
            Prev
          </Button>
        ) : (
          <Button
            variant="contained"
            disableElevation
            onClick={handlePrev}
            size="small"
            color="success"
          >
            Prev
          </Button>
        )}

        {!isLastPage ? (
          <Button
            variant="contained"
            disableElevation
            onClick={handleNext}
            size="small"
            color="success"
          >
            Next
          </Button>
        ) : (
          <Button
            variant="contained"
            disableElevation
            onClick={handleNext}
            size="small"
            color="success"
            disabled
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Pagination;
