import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PaystackButton } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/addons/Loader";
import NotFound from "../components/addons/NotFound";
import DashboardNavbar from "../components/DashboardNavbar";
import {
  checkValidity,
  reset,
} from "../features/check_originality/check_document_originality_slice";

import CheckDocument from "../components/ValidateDocs/CheckDocument";
import GetDocument from "../components/ValidateDocs/GetDocument";
import {
  reset as resetDoc,
  verifyPaymentInitializeValidation,
} from "../features/verify_documents/verify_documents_slice";

const DashboardVerifyDocs = () => {
  const { response, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.validate
  );

  const {
    isSuccess: success,
    isError: error,
    message: msg,
    response: res,
  } = useSelector((state) => state.documents);

  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const { uniqueId } = useParams();
  const [documentValidity, setDocumentValidity] = useState(true);
  const [surveyValidity, setSurveyValidity] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyTransaction = async (response) => {
    const data = {
      reference: response.reference,
      documentValidity,
      surveyValidity,
      id: user._id,
      uniqueId,
    };
    dispatch(verifyPaymentInitializeValidation(data));
  };

  const publicKey = "pk_test_333758610a4ecb30c56395e15428cfcbcf296c0c";

  const amount = 14000000;
  const fullname = `${user.Firstname} ${user.Lastname}`;
  const phone = `${user.Phone || null}`;
  const email = user.Email;
  const componentProps = {
    email,
    amount,
    metadata: {
      name: fullname,
      phone,
    },
    publicKey,
    text: "Proceed with payment",
    onSuccess: (response) => {
      verifyTransaction(response);
    },
    onClose: () => alert("Wait! Don't leave :("),
  };

  useEffect(() => {
    if (!response) {
      dispatch(checkValidity(uniqueId));
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          dispatch(reset());
          setLoading(false);
        },
      });
    }

    if (isSuccess) {
      dispatch(reset());
      setLoading(false);
    }

    dispatch(reset());
  }, [isError, isSuccess, dispatch, message]);

  useEffect(() => {
    if (error) {
      toast.error(msg, {
        onClose: () => {
          dispatch(resetDoc());
          setLoading(false);
        },
      });
    }

    if (success) {
      toast.success(msg, {
        onClose: () => {
          dispatch(resetDoc());
          setLoading(false);
          if (res?.LandId) {
            navigate("/dashboard/verificationLands");
          } else {
            navigate("/dashboard/verificationProperties");
          }
        },
      });
    }

    dispatch(resetDoc());
  }, [error, success, dispatch, msg]);

  return (
    <>
      <DashboardNavbar />
      <Box component="div" className="">
        <Container>
          <Grid container spacing={2} className="relative">
            <Grid item xs={12} sm={9} md={9}>
              <Box
                component="div"
                className="sm:p-5 p-1 h-screen relative bg-green-custom">
                <Box
                  component="div"
                  className="sm:p-3 p-0 mt-3 sm:mt-0  w-full">
                  <Link to="/dashboard">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        opacity: "90%",
                      }}>
                      Go Back
                    </Typography>
                  </Link>
                  <Box component="div" className=" p-2 ">
                    {loading && <Loader />}

                    {!loading && response === null && (
                      <>
                        <NotFound
                          type={
                            message?.includes("identified")
                              ? "notfound"
                              : "denied"
                          }
                        />

                        {message?.includes("Denied") && (
                          <Box style={{ position: "absolute", bottom: 120 }}>
                            <Typography
                              variant="body2"
                              sx={{
                                mt: 2,
                                fontWeight: "medium",
                                color: "green.800",
                              }}>
                              Document processing Fees:
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "green.700" }}>
                              • CAC : NGN 30,000
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "green.700" }}>
                              • Probate Court : NGN 30,000
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "green.700" }}>
                              • Land Registry : NGN 30,000
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "green.700" }}>
                              • Initial survey deposit: NGN 50,000
                            </Typography>

                            <Typography
                              variant="subtitle1"
                              sx={{
                                mt: 2,
                                fontWeight: "bold",
                                color: "green.900",
                              }}>
                              Total initial payment: NGN 140,000
                            </Typography>

                            <Typography
                              variant="body2"
                              sx={{
                                mt: 2,
                                fontStyle: "italic",
                                color: "green.800",
                              }}>
                              You'll be redirected to our secure payment portal
                              to complete the transaction.
                            </Typography>

                            <PaystackButton
                              {...componentProps}
                              className="bg-blue-700 text-white rounded-md text-[12px] mt-5 px-5 py-2"
                            />
                          </Box>
                        )}
                      </>
                    )}

                    {response && !response.type && !loading && (
                      <GetDocument
                        response={response}
                        documentValidity={documentValidity}
                        surveyValidity={surveyValidity}
                        componentProps={componentProps}
                      />
                    )}
                    {response && response.type === "check" && !loading && (
                      <CheckDocument
                        response={response}
                        documentValidity={documentValidity}
                        setDocumentValidity={setDocumentValidity}
                        surveyValidity={surveyValidity}
                        setSurveyValidity={setSurveyValidity}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              className="sm:block hidden"
              style={{ position: "fixed", top: "40px", right: "30px" }}>
              <Box
                component="div"
                className=" rounded-lg p-3 mt-5 "
                sx={{ height: "78vh" }}>
                <div className="flex justify-between items-center">
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      opacity: "90%",
                    }}>
                    Notifications
                  </Typography>
                  <NotificationsActiveIcon sx={{ color: "#22c55e" }} />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DashboardVerifyDocs;
