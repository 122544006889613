import React, { useEffect } from "react";
import FindlandBanner from "../components/FindLand/FindlandBanner";
import PropertyListings from "../components/FindLand/PropertyListings";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getAllProperties } from "../features/property/propertySlice";
import { useLocation, useParams } from "react-router-dom";
import { getAllLands } from "../features/land/landSlice";

const FindLand = () => {
  const dispatch = useDispatch();
  const { route } = useParams();
  const location = useLocation();

  const { property: properties, isLoading } = useSelector(
    (state) => state.property
  );

  const { land: lands } = useSelector((state) => state.land);

  useEffect(() => {
    dispatch(getAllProperties());
    dispatch(getAllLands());
  }, [location.pathname]);

  return (
    <>
      <Navbar />
      <FindlandBanner route={route} />
      <PropertyListings
        isLoading={isLoading}
        properties={route === "LandsListing" ? lands : properties}
        route={route}
      />
      <Footer />
    </>
  );
};

export default FindLand;
