import axios from "axios";
import { API_URL } from "../api";

const escrowPayment = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };
  const response = await axios.post(`${API_URL}/payments/escrow`, data, config);
  return response.data;
};

const paymentService = {
  escrowPayment,
};

export default paymentService;
