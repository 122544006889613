import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import signupImg from "../../assets/signup.svg";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { loginSchema } from "../../utils/Index"; // Ensure validateSchema is imported correctly
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { login, reset } from "../../features/auth/authSlice";

const LoginForm = ({ redirectFrom }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Email: "",
      Password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values, actions) => {
      dispatch(login(values));
    },
  });

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          setSubmitting(false);
        },
      });
    }

    if (isSuccess || user) {
      if (redirectFrom?.includes("/dashboard/verifydocs")) {
        navigate(redirectFrom);
      } else {
        navigate("/dashboard");
      }
    }

    dispatch(reset());
  }, [isError, isSuccess, dispatch, message]);

  const handleSignUpRoute = () => {
    if (redirectFrom.includes("/dashboard/verifydocs")) {
      navigate("/register", { state: { redirectFrom } });
    } else {
      navigate("/register");
    }
  };

  return (
    <Box>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7}>
            <Box component="div" className="sm:p-20 p-3">
              <Typography variant="h5" sx={{ lineHeight: "2.4rem" }}>
                Enter your <br />
                <Typography
                  variant="span"
                  sx={{
                    fontSize: "45px",
                    fontWeight: "bolder",
                    color: "#1d4ed8",
                  }}>
                  Account Details
                </Typography>
              </Typography>
              <Typography component="p" sx={{ fontSize: "12px" }}>
                Welcome back. Login to access our unique offerings that allow
                you sort out your real estate needs with ease.
              </Typography>
              <Box
                onSubmit={handleSubmit}
                component="form"
                sx={{
                  "& .MuiTextField-root": { width: "100%" },
                  padding: 0,
                  margin: "5% 0%",
                }}
                noValidate
                autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <TextField
                        label="Email Address"
                        id="Email"
                        size="small"
                        inputProps={{ style: { fontSize: 16 } }}
                        onChange={handleChange}
                        value={values.Email}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.Email && touched.Email && (
                      <Typography sx={{ fontSize: "11px", color: "red" }}>
                        {errors.Email}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <TextField
                        label="Password"
                        id="Password"
                        type={showPassword ? "text" : "password"} // Toggle between text and password
                        size="small"
                        inputProps={{ style: { fontSize: 16 } }}
                        value={values.Password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisibility}
                                edge="end">
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    {errors.Password && touched.Password && (
                      <Typography sx={{ fontSize: "11px", color: "red" }}>
                        {errors.Password}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{
                    bgcolor: "#1e40af",
                    textTransform: "none",
                    fontWeight: "bold",
                    marginTop: "3%",
                  }}
                  className="bg-blue-700"
                  size="medium"
                  type="submit">
                  {isSubmitting ? "Please wait..." : "Login"}
                </Button>
              </Box>
              <Typography
                component="p"
                sx={{ fontSize: "12px", fontWeight: "bold" }}>
                Don't have an account?
                <Typography
                  onClick={handleSignUpRoute}
                  component="span"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#3b82f6",
                    cursor: "pointer",
                  }}>
                  {" "}
                  <Link
                    to={
                      redirectFrom?.includes("/dashboard/verifydocs")
                        ? { pathname: "/register", state: { redirectFrom } }
                        : "/register"
                    }>
                    Sign Up
                  </Link>
                </Typography>
              </Typography>

              <Typography
                component="p"
                sx={{ fontSize: "12px", fontWeight: "bold" }}>
                Forgot Password?
                <Typography
                  component="span"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "#3b82f6",
                  }}>
                  <Link to="/forgot-password">Recover Password</Link>
                </Typography>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box component="div" className="mt-28">
              <img
                src={signupImg}
                alt="sign up images"
                width="80%"
                className="sm:block hidden"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LoginForm;
