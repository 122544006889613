import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import completeSvg from "../../assets/completeSVG.svg";
import searchSvg from "../../assets/search.svg";
import {
  finalizeLandVerification,
  initializeLandVerification,
  reset,
} from "../../features/verify_documents/verify_documents_slice";
import DisplaySearchAndSurveyDocument from "../Land_Property_Sidebar_Actions/DisplaySearchAndSurveyDocument";
import InitiateDocumentSearchAndSurvey from "../Land_Property_Sidebar_Actions/InitiateDocumentSearchAndSurvey";
import InitiateFinalPayment from "../Land_Property_Sidebar_Actions/InitiateFinalPayment";

const LandInfoSideBar = ({ land, user }) => {
  const [handleOpenPaymentModal, setOpenPaymentModal] = useState(false);
  const { isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.documents
  );

  const dispatch = useDispatch();

  const handleVerify = (reference) => {
    const landId = land?.lands?._id;
    const purpose = "Verification";
    dispatch(initializeLandVerification({ landId, purpose, reference }));
  };

  const handleFinalPaymentVerification = (reference) => {
    const landId = land?.lands?._id;
    const docId = land?.document?._id;
    dispatch(finalizeLandVerification({ landId, docId, reference }));
  };

  useEffect(() => {
    if (isError) {
      setOpenPaymentModal(false);
      Swal.fire({
        icon: "error",
        title: "Ooops",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        dispatch(reset());
        setOpenPaymentModal(false);
      });
    }

    if (isSuccess) {
      setOpenPaymentModal(false);
      Swal.fire({
        icon: "success",
        title: "",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        window.location.reload(false);
        dispatch(reset());
        setOpenPaymentModal(false);
      });
    }
  }, [isError, isSuccess, message, dispatch]);

  return (
    <Grid item xs={12} sm={12} md={12} lg={4}>
      <Box
        component="div"
        className=" bg-gray-100 rounded-lg p-3 mt-1 "
        sx={{ height: "78vh" }}>
        <div className="flex items-center justify-center">
          <img
            src={
              land?.document?.Status === "completed" ? completeSvg : searchSvg
            }
            alt="Complete SVG"
            className="h-[20vh]"
          />
        </div>
        {/* Checking if the land listed here isnt owned by the loggedIn User */}
        {(land?.lands?.UserId === user?._id &&
          land?.lands?.Listings === false) ||
        land?.lands?.UserId !== user?._id ? (
          <div className="sm:mt-20 mt-5">
            <div className="mt-5">
              <Stack spacing={2} direction="column">
                <>
                  {(land?.document?.SurveyStatus === "pending" &&
                    land?.document?.SurveyMessageStatus === false) ||
                  (land?.document?.Status === "pending" &&
                    land?.document?.ValidationMessageStatus === false) ? (
                    <>
                      <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                        We're actively conducting searches and survey for this
                        property. Thank you for your patience; we'll keep you
                        updated
                      </Typography>

                      <Button
                        variant="contained"
                        color="info"
                        disableElevation
                        size="medium"
                        sx={{
                          fontSize: "11px",
                          width: "100%",
                          textTransform: "none",
                        }}>
                        Search/Survey in Progress
                      </Button>
                    </>
                  ) : (land?.document?.SurveyStatus === "completed" ||
                      land?.document?.Status === "completed") &&
                    land?.document?.UnbalancedPaymentStatus === true ? (
                    <DisplaySearchAndSurveyDocument
                      land={land}
                      document={document}
                    />
                  ) : (land?.document?.SurveyStatus === "completed" ||
                      land?.document?.Status === "completed") &&
                    land?.document?.UnbalancedPaymentStatus === false ? (
                    <InitiateFinalPayment
                      isLoading={isLoading}
                      handleOpenPaymentModal={handleOpenPaymentModal}
                      handleFinalPaymentVerification={
                        handleFinalPaymentVerification
                      }
                      setOpenPaymentModal={setOpenPaymentModal}
                      document={land?.document}
                    />
                  ) : (
                    <InitiateDocumentSearchAndSurvey
                      isLoading={isLoading}
                      handleOpenPaymentModal={handleOpenPaymentModal}
                      handleVerify={handleVerify}
                      setOpenPaymentModal={setOpenPaymentModal}
                    />
                  )}
                </>
              </Stack>
            </div>
          </div>
        ) : (
          ""
        )}
      </Box>
    </Grid>
  );
};

export default LandInfoSideBar;
