import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkWithHandler } from "../api";
import paymentService from "./payment_service";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: null,
};

export const escrowPayment = createAsyncThunkWithHandler(
  "payments/escrowPayment",
  async (query, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    const data = await paymentService.escrowPayment({
      ...query,
      userId: userId,
      token,
    });

    return data;
  }
);

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(escrowPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(escrowPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(escrowPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = paymentsSlice.actions;
export default paymentsSlice.reducer;
