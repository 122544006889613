import React, { useEffect } from "react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import { useLocation } from "react-router-dom";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Access the state from the location object
    const state = location.state;

    if (state) {
      const { from, url } = state;

      // Additional logic based on the state
    }
  }, [location.state]);
  return (
    <>
      <Navbar />
      <Home />
      <Footer />
    </>
  );
};

export default App;
