import React from "react";

import PropertyInfo from "../components/LandOwner/PropertyInfo";
import Navbar from "../components/Navbar";

const PropertyDetails = () => {
  return (
    <>
      <Navbar />
      <PropertyInfo />
    </>
  );
};

export default PropertyDetails;
