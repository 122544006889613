import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { PDFDocument, StandardFonts, rgb, degrees } from "pdf-lib";
import { Watermark } from "antd";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function ValidateReport({
  setOpenReportModal,
  propertyType,
  comments,
  verificationDate,
  propertyLocation,
  propertyId,
  agent,
}) {
  const [loader, setLoader] = useState(false);

  const downloadPDF = async () => {
    try {
      setLoader(true);
      const capture = document.querySelector(".actual-receipt");

      // Convert the HTML to canvas
      const canvas = await html2canvas(capture);
      const imageDataUrl = canvas.toDataURL("image/png");

      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage([595, 842]); // A4 size in points

      // Convert data URL to Uint8Array
      const imageData = await fetch(imageDataUrl).then((res) =>
        res.arrayBuffer()
      );
      const image = await pdfDoc.embedPng(imageData);

      // Add the main content image
      page.drawImage(image, {
        x: 0,
        y: 0,
        width: page.getWidth(),
        height: page.getHeight(),
      });

      // Add watermark
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const fontSize = 20;
      const watermarkText1 = "Verified By";
      const watermarkText2 = "LockyourLand Legal Team";

      // Add multiple watermarks
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 4; j++) {
          const x = i * 200;
          const y = j * 200;

          page.drawText(watermarkText1, {
            x: x + 50,
            y: y + 100,
            size: fontSize,
            font: helveticaFont,
            color: rgb(0.8, 0.8, 0.8),
            opacity: 0.45,
            rotate: degrees(45),
          });

          page.drawText(watermarkText2, {
            x: x + 50,
            y: y + 50,
            size: fontSize,
            font: helveticaFont,
            color: rgb(0.8, 0.8, 0.8),
            opacity: 0.45,
            rotate: degrees(45),
          });
        }
      }

      // Save the PDF
      const pdfBytes = await pdfDoc.save();

      // Create a download link
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "verification_report.pdf";
      link.click();

      setLoader(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setLoader(false);
    }
  };

  return (
    <Watermark content={["Verified By", "LockyourLand"]}>
      <Paper elevation={3} className="actual-receipt" sx={{ p: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", color: "primary.main" }}>
          <span style={{ color: "green" }}>Lock.</span>
          <span style={{ color: "black" }}>your</span>
          <span style={{ color: "blue" }}>.land</span> Verification Report
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              Property Information
            </Typography>
            <Typography>
              <strong>Property Type:</strong> {propertyType}
            </Typography>
            <Typography>
              <strong>Location:</strong> {propertyLocation}
            </Typography>
            <Typography>
              <strong>Property ID:</strong> {propertyId}
            </Typography>
            <Typography>
              <strong>Verification Date:</strong> {verificationDate}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <QRCode
              value={`https://lockyourland.com/dashboard/verifydocs/${propertyId}`}
              size={128}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>
          Executive Summary
        </Typography>
        <Typography paragraph>
          This report provides a comprehensive overview of the property's key
          details, verification findings, and notable observations. Our team has
          conducted thorough checks to ensure the accuracy and legitimacy of all
          provided documents.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>
          Verification Process
        </Typography>
        <Typography paragraph>
          Our verification process included document reviews, on-site
          inspections, and collaboration with relevant authorities. We examined
          property deeds, contracts, and related documents to identify any
          discrepancies or inconsistencies.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>
          Key Findings
        </Typography>
        <Typography paragraph>
          <strong>Land Registry Verification:</strong> Ownership records and
          legal details confirmed with the local land registry.
        </Typography>
        <Typography paragraph>
          <strong>Probate Registry Verification:</strong> Legal status of the
          property and associated inheritances or claims verified.
        </Typography>
        <Typography paragraph>
          <strong>CAC Search:</strong> Legal status and details of any corporate
          entities associated with the property confirmed.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>
          Comments and Observations
        </Typography>
        <Typography paragraph>{comments}</Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>
          Legal Verification
        </Typography>
        <Typography>
          <strong>Lawyer's License:</strong> {agent?.AgentLicense}
        </Typography>
        <Typography>
          <strong>Lawyer's Name:</strong> {agent?.Lastname}, {agent?.Firstname}
        </Typography>

        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadPDF}
            disabled={loader}>
            {loader ? "Generating PDF..." : "Download Report"}
          </Button>
        </Box>
      </Paper>
    </Watermark>
  );
}
