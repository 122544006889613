import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { reset, update } from "../../features/auth/authSlice";
import { UpdateSchema } from "../../utils/Index";
import { countries, nigerianStates } from "../../utils/data";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdateProfileForm = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Phone: user?.Phone || "",
      Nin: user?.Nin || "",
      Address: user?.Address || "",
      State: user?.State || "",
      Country: user?.Country || "",
    },
    validationSchema: UpdateSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
      dispatch(update(formData));
    },
  });

  const [selectedCountry, setSelectedCountry] = useState(values.Country || "");

  const handleCountryChange = (e) => {
    const { value } = e.target;
    setSelectedCountry(value);
    setFieldValue("Country", value);
  };

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          setSubmitting(false);
        },
      });
    }

    if (isSuccess) {
      toast.info(message, {
        onClose: () => {
          setSubmitting(false);
        },
      });
      setOpen(false);
    }

    dispatch(reset());
  }, [isError, isSuccess, message, user]);

  return (
    <Box className="mt-5">
      <div>
        <Button
          variant="contained"
          sx={{
            bgcolor: "#1e40af",
            textTransform: "none",
            fontWeight: "bold",
            marginTop: "3%",
          }}
          className="bg-blue-700"
          size="medium"
          type="submit"
          onClick={handleOpen}>
          Update Profile
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}>
          <Fade in={open}>
            <Box sx={style}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Box component="div" className="">
                    <Box
                      onSubmit={handleSubmit}
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { width: "100%" },
                        padding: 0,
                        margin: "5% 0%",
                      }}
                      noValidate
                      autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <div>
                            <TextField
                              label="Phone Number"
                              id="Phone"
                              size="small"
                              inputProps={{ style: { fontSize: 12 } }}
                              onChange={handleChange}
                              value={values.Phone}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.Phone && touched.Phone && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.Phone}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <div>
                            <TextField
                              label="Address"
                              id="Address"
                              size="small"
                              inputProps={{ style: { fontSize: 12 } }}
                              onChange={handleChange}
                              value={values.Address}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.Address && touched.Address && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.Address}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <div>
                            <TextField
                              label="NIN"
                              id="Nin"
                              size="small"
                              inputProps={{ style: { fontSize: 12 } }}
                              onChange={handleChange}
                              value={values.Nin}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.Nin && touched.Nin && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.Nin}
                            </Typography>
                          )}
                        </Grid>
                        {/* Country Form */}
                        <Grid item xs={12} sm={6} md={6}>
                          <div>
                            <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                                sx={{ fontSize: "12px" }}>
                                Country
                              </InputLabel>
                              <Select
                                label="Country"
                                id="Country"
                                size="small"
                                className="w-full"
                                onChange={handleCountryChange}
                                value={selectedCountry}
                                onBlur={handleBlur}
                                inputProps={{ style: { fontSize: 12 } }}>
                                {countries.map((country) => (
                                  <MenuItem
                                    key={country.name}
                                    value={country.name}>
                                    {country.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          {errors.Country && touched.Country && (
                            <Typography sx={{ fontSize: "10px", color: "red" }}>
                              {errors.Country}
                            </Typography>
                          )}
                        </Grid>

                        {/* State form */}
                        <Grid item xs={12} sm={6} md={6}>
                          <div>
                            {selectedCountry === "Nigeria" ? (
                              <Select
                                label="State"
                                id="State"
                                size="small"
                                className="w-full"
                                onChange={(e) => {
                                  setFieldValue("State", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setFieldValue("State", e.target.value);
                                }}
                                value={values.State}
                                inputProps={{ style: { fontSize: 12 } }}>
                                {nigerianStates.map((state) => (
                                  <MenuItem key={state} value={state}>
                                    {state}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              <TextField
                                label="State"
                                placeholder="Enter state..."
                                id="State"
                                size="medium"
                                onChange={handleChange}
                                value={values.State}
                                onBlur={handleBlur}
                                inputProps={{ style: { fontSize: 12 } }}
                              />
                            )}
                          </div>
                          {errors.State && touched.State && (
                            <Typography sx={{ fontSize: "10px", color: "red" }}>
                              {errors.State}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>

                      <Button
                        variant="contained"
                        disabled={isSubmitting}
                        sx={{
                          bgcolor: "#1e40af",
                          textTransform: "none",
                          fontWeight: "bold",
                          marginTop: "3%",
                        }}
                        className="bg-blue-700 w-full"
                        size="medium"
                        type="submit">
                        {isSubmitting ? "Please wait ..." : "Update Profile"}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </div>
    </Box>
  );
};

export default UpdateProfileForm;
