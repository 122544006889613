import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkWithHandler } from "../api";
import landService from "./landService";
const initialState = {
  land: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getSingleLand = createAsyncThunkWithHandler(
  "land/getSingleLand",
  async (landId, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    return await landService.getSingleLand(landId, userId, token);
  }
);

export const upadteSingleLands = createAsyncThunkWithHandler(
  "land/upadteSingleLands",
  async (land, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const landData = land.formData;
    const landId = land.landId;
    return await landService.updateSingleLand(landData, landId, token);
  }
);

export const deleteLands = createAsyncThunkWithHandler(
  "land/deleteLands",
  async (landId, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    return await landService.deleteLand(landId, token);
  }
);

export const archiveLands = createAsyncThunkWithHandler(
  "land/archiveLands",
  async (landId, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    return await landService.archiveLand(landId, token);
  }
);
export const unarchiveLands = createAsyncThunkWithHandler(
  "land/unarchiveLands",
  async (landId, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    return await landService.unarchiveLand(landId, token);
  }
);

export const single_landSlice = createSlice({
  name: "singleLand",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getSingleLand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleLand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = action.payload;
      })
      .addCase(getSingleLand.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.land = null;
      })
      .addCase(upadteSingleLands.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(upadteSingleLands.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = action.payload;
        state.message = "You have updated land details successfully";
      })
      .addCase(upadteSingleLands.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.land = null;
      })
      .addCase(archiveLands.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(archiveLands.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = action.payload;
        state.message = "This land has been archived successfully";
      })
      .addCase(archiveLands.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.land = null;
      })
      .addCase(unarchiveLands.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unarchiveLands.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = action.payload;
        state.message = "This land has been activated successfully";
      })
      .addCase(unarchiveLands.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.land = null;
      })
      .addCase(deleteLands.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteLands.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = action.payload;
        state.message = "This land has been deleted successfully";
      })
      .addCase(deleteLands.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.land = null;
      });
  },
});

export const { reset } = single_landSlice.actions;
export default single_landSlice.reducer;
