import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkWithHandler } from "../api";
import { logoutError } from "../auth/authService";
import documentService from "./verify_documents_service";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: null,
  response: null,
};

export const initializePropertyVerification = createAsyncThunkWithHandler(
  "documents/verifyProperty",
  async (value, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    return await documentService.initializePropertyVerification({
      propertyId: value.propertyId,
      purpose: value.purpose,
      reference: value.reference,
      userId: userId,
      token: token,
    });
  }
);

export const finalizePropertyVerification = createAsyncThunkWithHandler(
  "documents/finalizePropertyVerification",
  async (value, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    return await documentService.finalizePropertyVerification({
      propertyId: value.propertyId,
      docId: value.docId,
      reference: value.reference,
      userId: userId,
      token: token,
    });
  }
);

export const verifyPaymentInitializeValidation = createAsyncThunkWithHandler(
  "documents/verifyPaymentAndInitialize",
  async (value, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    return await documentService.verifyPaymentInitializeValidation({
      ...value,
      token: token,
    });
  }
);

export const initializeLandVerification = createAsyncThunkWithHandler(
  "documents/verifyLand",
  async (value, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    return await documentService.initializeLandVerification({
      landId: value.landId,
      purpose: value.purpose,
      reference: value.reference,
      userId: userId,
      token: token,
    });
  }
);

export const finalizeLandVerification = createAsyncThunkWithHandler(
  "documents/finalizeLandVerification",
  async (value, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    return await documentService.finalizeLandVerification({
      landId: value.landId,
      docId: value.docId,
      reference: value.reference,
      userId: userId,
      token: token,
    });
  }
);

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializePropertyVerification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initializePropertyVerification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Property Validation has been initialized";
        state.response = action.payload;
      })
      .addCase(initializePropertyVerification.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.response = null;
      })
      .addCase(verifyPaymentInitializeValidation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyPaymentInitializeValidation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Validation has been initialized";
        state.response = action.payload;
      })
      .addCase(verifyPaymentInitializeValidation.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.response = null;
      })

      .addCase(initializeLandVerification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initializeLandVerification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Land Validation has been initialized";
        state.response = action.payload;
      })
      .addCase(initializeLandVerification.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.response = null;
      })
      .addCase(finalizeLandVerification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(finalizeLandVerification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Land Validation has been finalized";
        state.response = action.payload;
      })
      .addCase(finalizeLandVerification.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.response = null;
      })
      .addCase(finalizePropertyVerification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(finalizePropertyVerification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Property Validation has been finalized";
        state.response = action.payload;
      })
      .addCase(finalizePropertyVerification.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.response = null;
      });
  },
});

export const { reset } = documentsSlice.actions;
export default documentsSlice.reducer;
