import React from "react";
import ForgotPasswordForm from "../components/Login/ForgotPasswordForm";
import Navbar from "../components/Navbar";

const ForgotPassword = () => {
  return (
    <>
      <Navbar />
      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPassword;
