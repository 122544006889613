import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
} from "@mui/material";
import "tailwindcss/tailwind.css";
import { useDispatch, useSelector } from "react-redux";
import { getDocument } from "../../features/check_originality/check_document_originality_slice";
import { useParams } from "react-router-dom";
import NotFound from "../addons/NotFound";

const CheckDocument = ({
  documentValidity,
  setDocumentValidity,
  surveyValidity,
  setSurveyValidity,
}) => {
  const { response, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.validate
  );
  const { user } = useSelector((state) => state.auth);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { uniqueId } = useParams();

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    switch (name) {
      case "documentValidity":
        setDocumentValidity(checked);
        break;
      case "surveyValidity":
        setSurveyValidity(checked);
        break;
      default:
        break;
    }
  };

  const handlePostRequest = () => {
    // if (!documentValidity && !surveyValidity) {
    //   setError("Please select at least one option.");
    //   return;
    // }

    // Reset the error state if at least one checkbox is checked
    setError("");

    // You can perform the post request here with the selected checkboxes
    const postData = {
      documentValidity,
      surveyValidity,
      uniqueId,
    };

    dispatch(getDocument(postData));
  };

  useEffect(() => {
    handlePostRequest();
  }, []);

  return (
    <>
      {response?.doc === null ? (
        <NotFound type="notValidated" />
      ) : (
        <Box className="mx-auto mt-8 ">
          {/* <Typography
            variant="h4"
            className="mb-4"
            style={{
              fontSize: "16px",
              color: "#333",
              opacity: 0.9,
              fontWeight: "bold",
            }}>
            Hey {user.Lastname}, what do you want to validate with{" "}
            <span className="text-green-600 font-bold sm:text-xl text-md">
              Lock.
            </span>
            <span className="font-bold text-neutral-900 sm:text-xl text-md">
              your
            </span>
            <span className="text-blue-800 font-bold sm:text-xl text-md">
              .land
            </span>{" "}
            on this document? Check the box below if the information is valid:
          </Typography>

          <Box className="flex flex-col mt-5">
            {response?.doc?.Status === "completed" &&
            response?.doc?.SurveyStatus === "completed" ? (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={documentValidity}
                      onChange={handleCheckboxChange}
                      name="documentValidity"
                      color="primary"
                    />
                  }
                  label="Document Validity"
                  className="opacity-60 text-sm font-bold block "
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={surveyValidity}
                      onChange={handleCheckboxChange}
                      name="surveyValidity"
                      color="primary"
                    />
                  }
                  label="Survey Validity"
                  className="opacity-60 block "
                />
              </>
            ) : response?.doc?.Status === "completed" &&
              response?.doc?.SurveyStatus === null ? (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={documentValidity}
                    onChange={handleCheckboxChange}
                    name="documentValidity"
                    color="primary"
                  />
                }
                label="Document Validity"
                className="opacity-60 text-sm font-bold block "
              />
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={surveyValidity}
                    onChange={handleCheckboxChange}
                    name="surveyValidity"
                    color="primary"
                  />
                }
                label="Survey Validity"
                className="opacity-60 block "
              />
            )}
          </Box>

          {error && (
            <Typography variant="body2" color="error" className="mt-2">
              {error}
            </Typography>
          )} */}
          {/* 
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            sx={{ fontSize: "11px", fontWeight: "bold", marginTop: 1 }}
            onClick={handlePostRequest}>
            Proceed
          </Button> */}
        </Box>
      )}
    </>
  );
};

export default CheckDocument;
