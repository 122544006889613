import React from "react";
import { Box, Typography, Avatar, Chip } from "@mui/material";
import verified from "../../assets/verified.png";
import UpdateProfileForm from "../Login/UpdateProfileForm";
import Tooltip from "@mui/material/Tooltip";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import { useSelector } from "react-redux";
import { url } from "../../utils/Index";
import {
  maskAddress,
  maskEmail,
  maskPhoneNumber,
} from "../../helpers/maskedFunctions";
const LandOwnerInfo = ({ details: OwnerDetails }) => {
  var imgURL = `${url}/uploads/${OwnerDetails?.Profile}`;

  const { user } = useSelector((state) => state.auth);

  const details = [
    {
      Title: maskEmail(OwnerDetails?.Email) || "",
      Icon: <MailOutlineIcon style={{ fontSize: "17px" }} />,
    },
    {
      Title: maskPhoneNumber(OwnerDetails?.Phone.toString()) || "",
      Icon: <PhoneInTalkIcon style={{ fontSize: "17px" }} />,
    },
    {
      Title: maskAddress(OwnerDetails?.Address) || "",
      Icon: <HomeOutlinedIcon style={{ fontSize: "17px" }} />,
    },
    {
      Title: OwnerDetails?.State || "",
      Icon: <AddLocationAltOutlinedIcon style={{ fontSize: "17px" }} />,
    },
    {
      Title: OwnerDetails?.Country || "",
      Icon: <TourOutlinedIcon style={{ fontSize: "17px" }} />,
    },
  ];
  return (
    <Box component="div" className=" p-3 flex   flex-col">
      <Avatar
        alt="Remy Sharp"
        src={imgURL}
        sx={{ height: "70px", width: "70px" }}
      />
      <>
        <Box className="flex items-center ">
          <Typography
            variant="h6"
            sx={{
              fontSize: "13px",
              fontWeight: "bold",
              marginTop: "2%",
              textTransform: "uppercase",
            }}>
            {OwnerDetails?.Firstname || OwnerDetails?.Firstname}{" "}
            {OwnerDetails?.Lastname || OwnerDetails?.Lastname}
          </Typography>
          {OwnerDetails?.VerifiedUser === "completed" && (
            <img src={verified} width="20px" />
          )}
        </Box>

        {OwnerDetails?.VerifiedUser === "completed" ? (
          <Box className={`${user ? null : "blur-sm"}`}>
            {details.map((detail, index) => {
              return (
                <Box className="flex space-x-1 items-center mb-3" key={index}>
                  {detail.Icon}
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "13px",
                      fontWeight: "bold",
                      marginTop: "0%",
                      textTransform: "",
                      opacity: "70%",
                      textTransform: "capitalize",
                    }}>
                    {detail.Title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        ) : null}

        {!OwnerDetails?.VerifiedUser === "completed" && (
          <Tooltip title="Update Profile to get verified">
            <Chip
              label="Not Verified"
              color="error"
              variant="outlined"
              className="w-5/12"
              size="small"
            />
          </Tooltip>
        )}
      </>
    </Box>
  );
};

export default LandOwnerInfo;
