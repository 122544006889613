import axios from "axios";
import { API_URL } from "../api";

const initializePropertyVerification = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/documents/verify_documents/property/${data.reference}`,
    data,
    config
  );
  return response.data;
};

const initializeLandVerification = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/documents/verify_documents/land/${data.reference}`,
    data,
    config
  );
  return response.data;
};

const finalizeLandVerification = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/documents/verify_documents/land/finalize/${data.reference}`,
    data,
    config
  );
  return response.data;
};

const finalizePropertyVerification = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/documents/verify_documents/property/finalize/${data.reference}`,
    data,
    config
  );
  return response.data;
};

const verifyPaymentInitializeValidation = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/documents/verifydocs/payment/${data.reference}/${data.uniqueId}/${data.id}`,
    data,
    config
  );

  // console.log(response.data);

  return response.data;
};

const documentService = {
  initializePropertyVerification,
  initializeLandVerification,
  finalizeLandVerification,
  finalizePropertyVerification,
  verifyPaymentInitializeValidation,
};

export default documentService;
