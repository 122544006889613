import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/addons/Loader";
import NotFound from "../components/addons/NotFound";
import Pagination from "../components/addons/Pagination";
import DashboardNavbar from "../components/DashboardNavbar";
import UserDetails from "../components/userInformation/UserDetails";
import useSaveDraft from "../hooks/form-hooks/local-databse/useSaveDraft";

const DashboardRealtorDrafts = () => {
  const { saveDraft, loadAllDrafts, isLoading, drafts } = useSaveDraft();

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(4);
  const [searchTerm, setSearchTerm] = useState("");
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  useEffect(() => {
    loadAllDrafts();
  }, []);

  const items = [];

  const totalPrice = drafts?.reduce((sum, item) => sum + item.values.Price, 0);
  drafts?.map((draft) => {
    items.push({
      Image: draft?.values?.Images?.[0]
        ? URL.createObjectURL(draft?.values?.Images[0])
        : null,
      Address: draft?.values?.Address,
      Price: draft?.values?.Price,
      Size: draft?.values?.Size,
      Currency: draft?.values?.Currency,
      CreatedAt: draft?.values?.CreatedAt,
      id: draft?.draftId,
      type: draft?.name,
      Timestamp: draft?.lastSaved.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true, // Set to false for 24-hour format
      }),
    });
  });
  var items2;

  items2 = items?.filter(
    (draft) =>
      draft?.Address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      draft?.UniqueId?.includes(searchTerm)
  );

  const paginatedItems = items2?.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <>
      <DashboardNavbar />
      <Box component="div" sx={{ height: "90vh" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <UserDetails />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                component="div"
                className="sm:p-5 p-5  bg-green-custom"
                sx={{ height: "90vh" }}>
                <Box component="div" className="p-3 mt-3 sm:mt-0  w-full">
                  <Link to="/dashboard">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        opacity: "90%",
                      }}>
                      Go Back
                    </Typography>
                  </Link>

                  <Box component="div" className=" p-2">
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}>
                      Your Properties on Drafts
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "",
                        marginTop: 2,
                      }}>
                      Total Amount on Escrow: NGN {totalPrice?.toLocaleString()}
                    </Typography>
                    {drafts?.length > 0 && (
                      <Box className="my-3">
                        <TextField
                          id="standard-search"
                          label="Search field"
                          type="search"
                          sx={{ fontSize: "12px" }}
                          variant="standard"
                          placeholder="Enter Land ID"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </Box>
                    )}
                    {isLoading ? (
                      <Loader />
                    ) : !isLoading && drafts?.length === 0 ? (
                      <NotFound />
                    ) : (
                      <Box>
                        <Grid container spacing={2}>
                          {paginatedItems?.map((item, index) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                className=" flex flex-col items-center justify-center "
                                sx={{ marginTop: "2%" }}>
                                <Link
                                  to={
                                    item?.type == "Land"
                                      ? `/verifyLandForm/${item?.id}`
                                      : item?.type == "Property"
                                      ? `/verifyPropertyForm/${item?.id}`
                                      : item?.type == "Sell_Land"
                                      ? `/sellLandForm/${item?.id}`
                                      : `/sellPropertyForm/${item?.id}`
                                  }>
                                  <Box component="div" className="">
                                    <img
                                      src={
                                        item.Image ||
                                        item?.type?.includes("Property")
                                          ? "https://www.bankrate.com/2022/09/13091209/tips-for-selling-your-home.jpg?auto=webp&optimize=high&crop=16:9"
                                          : "https://t3.ftcdn.net/jpg/04/46/93/92/360_F_446939257_j3c5pnVVJPnGoCdve7pjLRk5kLYICXzs.jpg"
                                      }
                                      alt="house Image"
                                      className="rounded-xl w-96 h-32"
                                    />
                                    <Box component="div">
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          fontWeight: "bold",
                                          textAlign: "left",
                                          fontSize: "12px",
                                        }}>
                                        {item.Address}
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          textAlign: "left",
                                          fontSize: "11px",
                                        }}>
                                        <i>{item.Timestamp}</i>
                                      </Typography>
                                      <Box className="flex items-center justify-between mt-3">
                                        <Box>
                                          <Typography
                                            variant="h1"
                                            sx={{
                                              opacity: "0.8",
                                              fontSize: "11px",
                                              textAlign: "left",
                                            }}>
                                            Size
                                          </Typography>
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: "12px" }}>
                                            {item.Size}
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Typography
                                            variant="h1"
                                            sx={{
                                              opacity: "0.8",
                                              fontSize: "11px",
                                              textAlign: "left",
                                            }}>
                                            Price
                                          </Typography>
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ fontSize: "12px" }}>
                                            {item?.Currency}{" "}
                                            {item.Price.toLocaleString()}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Link>
                              </Grid>
                            );
                          })}
                        </Grid>
                        {usersPerPage < drafts?.length &&
                        drafts?.length != 0 ? (
                          <Pagination
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            properties={drafts}
                            indexOfLastUser={indexOfLastUser}
                            usersPerPage={usersPerPage}
                          />
                        ) : null}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Box
                component="div"
                className=" rounded-lg p-3 mt-5 sm:block hidden"
                sx={{ height: "78vh" }}>
                <div className="flex justify-between items-center">
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      opacity: "90%",
                    }}>
                    Notifications
                  </Typography>
                  <NotificationsActiveIcon sx={{ color: "#22c55e" }} />
                </div>
                <div></div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DashboardRealtorDrafts;
