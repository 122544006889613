import { ArrowRight, Clock, Mail } from "lucide-react";
import React from "react";
import Navbar from "../components/Navbar";

const InfoRegPage = () => {
  const openEmailApp = () => {
    window.location.href = "mailto:";
  };

  const contactSupport = () => {
    window.location.href = "mailto:support@lockyourland.com";
  };

  return (
    <>
      <Navbar />
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <div className="w-full max-w-md">
          <div className="text-center">
            <div className="mx-auto bg-blue-100 w-16 h-16 rounded-full flex items-center justify-center mb-4">
              <Mail className="h-8 w-8 text-blue-600" />
            </div>
            <h3 className="text-2xl font-bold text-gray-900">
              Check Your Email
            </h3>
            <h5 className="text-gray-500 mt-2">
              We've sent a verification link to your email address
            </h5>
          </div>
          <div>
            <div className="space-y-4">
              <div>
                <h6 className="text-sm text-gray-600">
                  Please click the link in the email to verify your account. If
                  you don't see the email, check your spam folder.
                </h6>
              </div>

              <div className="flex items-center justify-center gap-2 text-sm text-gray-600 bg-gray-100 p-3 rounded-lg">
                <Clock className="h-4 w-4" />
                <span>Verification link expires in 24 hours</span>
              </div>

              <button
                onClick={openEmailApp}
                className="w-full flex items-center justify-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors">
                Open Email App
                <ArrowRight className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>

        <p className="mt-8 text-gray-500 text-sm text-center">
          Need help?{" "}
          <button
            onClick={contactSupport}
            className="text-blue-600 hover:text-blue-800">
            Contact Support
          </button>
        </p>
      </div>
    </>
  );
};

export default InfoRegPage;
