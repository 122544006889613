import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import verifiedImg from "../../assets/verified.png";
import pendingImg from "../../assets/pending.png";
import unverifiedImg from "../../assets/unverified.png";
import ValidateReport from "../../components/addons/ValidateReport";
import { url } from "../../utils/Index";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PaystackButton } from "react-paystack";

const SurveryAccordion = ({ response }) => {
  return (
    <Accordion
      sx={{
        background: "#fafafa",
        padding: "0% 0%",
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}>
          Property Survey Documents
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "5px" }}>
        <Grid container className="space-y-4">
          {/* Owners details.. */}
          <Grid item xs={12} md={12} lg={12} xl={6}>
            <Box className="">
              <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                <Typography
                  varaint="h3"
                  className=" mb-5 font-bold uppercase"
                  style={{
                    fontWeight: "bold",
                    marginBottom: 15,
                  }}>
                  Owners Information: <br />
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Fullname: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Firstname}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Lastname: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Lastname}
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Gender: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Gender}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Phone: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Phone}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Email: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Email}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Country: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Country}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      State: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.State}
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Address: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Address}
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">Status</p>
                    <Chip
                      label={
                        response?.owner?.VerifiedUser === "completed"
                          ? "Verified"
                          : response?.owner?.VerifiedUser === "Not Verified"
                          ? "Not Verified"
                          : response?.owner?.VerifiedUser === "pending"
                          ? "Pending"
                          : null
                      }
                      size="small"
                      color={
                        response?.owner?.VerifiedUser === "completed"
                          ? "success"
                          : response?.owner?.VerifiedUser === "Not Verified"
                          ? "error"
                          : response?.owner?.VerifiedUser === "pending"
                          ? "warning"
                          : null
                      }
                      icon={
                        <img
                          src={
                            response?.owner?.VerifiedUser === "completed"
                              ? verifiedImg
                              : response?.owner?.VerifiedUser === "Not Verified"
                              ? unverifiedImg
                              : response?.owner?.VerifiedUser === "pending"
                              ? pendingImg
                              : null
                          }
                          alt="verified img"
                          className="w-4 h-4"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          {/* Document Details */}
          <Grid item xs={12} md={12} lg={12} xl={6}>
            <Box className="">
              <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                <Typography
                  varaint="h3"
                  className=" mb-5 font-bold uppercase"
                  style={{
                    fontWeight: "bold",
                    marginBottom: 15,
                  }}>
                  Property Information: <br />
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Country: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Country}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      State: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.State}
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Area: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Area}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Address: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Address}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Price: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Currency}{" "}
                        {response?.docDetails?.Price.toLocaleString()}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Escrow: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Currenc9y}{" "}
                        {response?.docDetails?.Escrow.toLocaleString()}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Size: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Size}
                        {response?.docDetails?.Measurement}
                      </span>
                    </p>
                  </Grid>

                  {response?.docDetails?.SittingRooms && (
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        SittingRooms: <br />{" "}
                        <span className="font-bold">
                          {response?.docDetails?.SittingRooms}
                        </span>
                      </p>
                    </Grid>
                  )}
                  {response?.docDetails?.BedRooms && (
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        BedRooms: <br />{" "}
                        <span className="font-bold">
                          {response?.docDetails?.BedRooms}
                        </span>
                      </p>
                    </Grid>
                  )}

                  {response?.docDetails?.RestRooms && (
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        RestRooms: <br />{" "}
                        <span className="font-bold">
                          {response?.docDetails?.RestRooms}
                        </span>
                      </p>
                    </Grid>
                  )}
                  {response?.docDetails?.OtherRooms && (
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        OtherRooms: <br />{" "}
                        <span className="font-bold">
                          {response?.docDetails?.OtherRooms}
                        </span>
                      </p>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      UniqueId: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.UniqueId}
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900"> Survey Status</p>
                    <Chip
                      label={
                        response?.documents?.SurveyStatus === "completed"
                          ? "Verified"
                          : response?.documents?.SurveyStatus === "Not Verified"
                          ? "Not Verified"
                          : response?.documents?.SurveyStatus === "pending"
                          ? "Pending"
                          : null
                      }
                      size="small"
                      color={
                        response?.documents?.SurveyStatus === "completed"
                          ? "success"
                          : response?.documents?.SurveyStatus === "Not Verified"
                          ? "error"
                          : response?.documents?.SurveyStatus === "pending"
                          ? "warning"
                          : null
                      }
                      icon={
                        <img
                          src={
                            response?.documents?.SurveyStatus === "completed"
                              ? verifiedImg
                              : response?.documents?.SurveyStatus ===
                                "Not Verified"
                              ? unverifiedImg
                              : response?.documents?.SurveyStatus === "pending"
                              ? pendingImg
                              : null
                          }
                          alt="verified img"
                          className="w-4 h-4"
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Box className="mt-5">
                  <a href={`${response?.documents?.SurveyProof}`}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      disableElevation
                      sx={{
                        textTransform: "none",
                        marginTop: "3%",
                        fontWeight: "bold",
                        width: "100%",
                      }}>
                      View Survey Document
                    </Button>
                  </a>

                  <p className="text-blue-900 mt-3">
                    Comment: <br />{" "}
                    <span className="font-bold">
                      {response?.documents?.SurveyComment}
                    </span>
                  </p>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const DocumentAccordion = ({ response }) => {
  return (
    <Accordion
      sx={{
        background: "#fafafa",
        padding: "0% 0%",
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}>
          Property validation Documents
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "5px" }}>
        <Grid container className="space-y-4">
          <Grid item xs={12} md={12} lg={12} xl={6}>
            <ValidateReport
              propertyType={
                response?.accessFile?.docType === "Property"
                  ? "Built Property"
                  : "Landed Property"
              }
              propertyId={response?.docDetails?.UniqueId}
              propertyLocation={`${response?.docDetails?.Address}, ${response?.docDetails?.Area}, ${response?.docDetails?.State} , ${response?.docDetails?.Country}`}
              verificationDate={new Date(
                response?.documents?.ValidatedTimeByAgent
              ).toLocaleString()}
              comments={response?.documents?.Comment}
              agent={response.agent}
            />
          </Grid>

          {/* Owners details.. */}
          <Grid item xs={12} md={12} lg={12} xl={6}>
            <Box className="">
              <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                <Typography
                  varaint="h3"
                  className=" mb-5 font-bold uppercase"
                  style={{
                    fontWeight: "bold",
                    marginBottom: 15,
                  }}>
                  Owners Information: <br />
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Fullname: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Firstname}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Lastname: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Lastname}
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Gender: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Gender}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Phone: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Phone}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Email: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Email}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Country: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Country}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      State: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.State}
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Address: <br />{" "}
                      <span className="font-bold">
                        {response?.owner?.Address}
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">Status</p>
                    <Chip
                      label={
                        response?.owner?.VerifiedUser === "completed"
                          ? "Verified"
                          : response?.owner?.VerifiedUser === "Not Verified"
                          ? "Not Verified"
                          : response?.owner?.VerifiedUser === "pending"
                          ? "Pending"
                          : null
                      }
                      size="small"
                      color={
                        response?.owner?.VerifiedUser === "completed"
                          ? "success"
                          : response?.owner?.VerifiedUser === "Not Verified"
                          ? "error"
                          : response?.owner?.VerifiedUser === "pending"
                          ? "warning"
                          : null
                      }
                      icon={
                        <img
                          src={
                            response?.owner?.VerifiedUser === "completed"
                              ? verifiedImg
                              : response?.owner?.VerifiedUser === "Not Verified"
                              ? unverifiedImg
                              : response?.owner?.VerifiedUser === "pending"
                              ? pendingImg
                              : null
                          }
                          alt="verified img"
                          className="w-4 h-4"
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          {/* Document Details */}
          <Grid item xs={12} md={12} lg={12} xl={6}>
            <Box className="">
              <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                <Typography
                  varaint="h3"
                  className=" mb-5 font-bold uppercase"
                  style={{
                    fontWeight: "bold",
                    marginBottom: 15,
                  }}>
                  Property Information: <br />
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Country: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Country}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      State: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.State}
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Area: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Area}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Address: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Address}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Price: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Currency}{" "}
                        {response?.docDetails?.Price.toLocaleString()}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Escrow: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Currenc9y}{" "}
                        {response?.docDetails?.Escrow.toLocaleString()}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      Size: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.Size}
                        {response?.docDetails?.Measurement}
                      </span>
                    </p>
                  </Grid>

                  {response?.docDetails?.SittingRooms && (
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        SittingRooms: <br />{" "}
                        <span className="font-bold">
                          {response?.docDetails?.SittingRooms}
                        </span>
                      </p>
                    </Grid>
                  )}
                  {response?.docDetails?.BedRooms && (
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        BedRooms: <br />{" "}
                        <span className="font-bold">
                          {response?.docDetails?.BedRooms}
                        </span>
                      </p>
                    </Grid>
                  )}

                  {response?.docDetails?.RestRooms && (
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        RestRooms: <br />{" "}
                        <span className="font-bold">
                          {response?.docDetails?.RestRooms}
                        </span>
                      </p>
                    </Grid>
                  )}
                  {response?.docDetails?.OtherRooms && (
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        OtherRooms: <br />{" "}
                        <span className="font-bold">
                          {response?.docDetails?.OtherRooms}
                        </span>
                      </p>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={4}>
                    <p className="text-blue-900">
                      UniqueId: <br />{" "}
                      <span className="font-bold">
                        {response?.docDetails?.UniqueId}
                      </span>
                    </p>
                  </Grid>

                  {
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900"> Document Status</p>
                      <Chip
                        label={
                          response?.documents?.Status === "completed"
                            ? "Verified"
                            : response?.documents?.Status === "Not Verified"
                            ? "Not Verified"
                            : response?.documents?.Status === "pending"
                            ? "Pending"
                            : null
                        }
                        size="small"
                        color={
                          response?.documents?.Status === "completed"
                            ? "success"
                            : response?.documents?.Status === "Not Verified"
                            ? "error"
                            : response?.documents?.Status === "pending"
                            ? "warning"
                            : null
                        }
                        icon={
                          <img
                            src={
                              response?.documents?.Status === "completed"
                                ? verifiedImg
                                : response?.documents?.Status === "Not Verified"
                                ? unverifiedImg
                                : response?.documents?.Status === "pending"
                                ? pendingImg
                                : null
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                  }
                </Grid>
                <Box className="mt-5">
                  <p className="text-blue-900">
                    Document Validation Receipts:{" "}
                  </p>

                  <Grid container spacing={4}>
                    <Grid item sm={12} md={12}>
                      <Grid container spacing={2}>
                        <Grid item sm={12} md={4}>
                          <Link
                            to={`${response?.documents?.DocumentProof?.[0].LandRegistory}`}>
                            <Box className="p-2 rounded-md bg-green-700 text-center cursor-pointer">
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "#fff",
                                }}>
                                Land Registory Document
                              </Typography>
                            </Box>
                          </Link>
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <Link
                            to={`${response?.documents?.DocumentProof?.[1].ProbateRegistory}`}>
                            <Box className="p-2 rounded-md bg-blue-700 text-center cursor-pointer">
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "#fff",
                                }}>
                                Probate Registory Document
                              </Typography>
                            </Box>
                          </Link>
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <Link
                            to={`${response?.documents?.DocumentProof?.[2].CacSearch}`}>
                            <Box className="p-2 rounded-md bg-cyan-700 text-center cursor-pointer">
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "#fff",
                                }}>
                                CAC Search
                              </Typography>
                            </Box>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const GetDocument = ({ response, componentProps }) => {
  return (
    <>
      {response?.accessFile?.DocumentValidityAccess &&
      response?.accessFile?.DocumentSurveyAccess ? (
        <Box className="space-y-5">
          <DocumentAccordion response={response} />
          <SurveryAccordion response={response} />
        </Box>
      ) : (
        <>
          <Box>
            <Box style={{ position: "absolute", bottom: 120 }}>
              <Typography variant="subtitle2">
                Please proceed to make your payment to access the validity of
                the documents you requested.
              </Typography>
              <PaystackButton
                {...componentProps}
                amount={1000000}
                className="bg-blue-700 text-white rounded-md text-[12px] mt-5 px-5 py-2"
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default GetDocument;
