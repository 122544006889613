import axios from "axios";
import { API_URL } from "../api";

const addLands = async (values, token, UserId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/lands/${UserId}`,
    values,
    config
  );
  return response.data;
};
const addInitializeLandValidation = async (
  values,
  reference,
  token,
  UserId
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/lands/addInitialize/${reference}/${UserId}`,
    values,
    config
  );

  return response.data;
};

const getAllLands = async () => {
  const response = await axios.get(`${API_URL}/lands`);
  return response.data;
};

const getLandsByUser = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/lands/users/user/${id}`, config);

  return response.data;
};

const getArchivedLandsByUser = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${API_URL}/lands/user/archived/${id}`,
    config
  );
  return response.data;
};

const getLandsOnVerificationByUser = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_URL}/lands/user/verifying_land/${id}`,
    config
  );

  return response.data;
};

const getSingleLands = async (id) => {
  const response = await axios.get(`${API_URL}/lands/${id}`);
  return response.data;
};

const authService = {
  addLands,
  addInitializeLandValidation,
  getAllLands,
  getSingleLands,
  getLandsByUser,
  getArchivedLandsByUser,
  getLandsOnVerificationByUser,
};

export default authService;
