import axios from "axios";
import { API_URL } from "../api";

//Register user

const getSingleLand = async (landId, userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${API_URL}/lands/${landId}/${userId}`,
    config
  );
  return response.data;
};

const updateSingleLand = async (data, landId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/lands/${landId}/update_land`,
    data,
    config
  );
  return response.data;
};

const archiveLand = async (landId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/lands/${landId}/archive_land`,
    "",
    config
  );
  return response.data;
};

const unarchiveLand = async (landId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/lands/${landId}/unarchive_land`,
    "",
    config
  );
  return response.data;
};

const deleteLand = async (landId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${API_URL}/lands/${landId}/delete_land`,
    config
  );
  return response.data;
};

const authService = {
  getSingleLand,
  archiveLand,
  updateSingleLand,
  deleteLand,
  unarchiveLand,
};

export default authService;
