import { Box } from "@mui/material";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
const Loader = () => {
  return (
    <Box className="flex my-5 items-center justify-center">
      <ClipLoader
        color="#1e40af"
        loading="true"
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </Box>
  );
};

export default Loader;
