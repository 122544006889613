import axios from "axios";
import { API_URL } from "../api";

const addProperties = async (values, token, UserId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/properties/${UserId}`,
    values,
    config
  );
  return response.data;
};

const addInitializePropertyValidation = async (
  values,
  paystackReference,
  token,
  UserId
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/properties/addInitialize/${paystackReference}/${UserId}`,
    values,
    config
  );

  return response.data;
};

const getPropertiesByUser = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_URL}/properties/users/user/${id}`,
    config
  );

  return response.data;
};
const getArchivedPropertiesByUser = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_URL}//propertiesuser/archived/${id}`,
    config
  );

  return response.data;
};

const getEscrowPropertiesByUser = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_URL}/properties/user/escrow/${id}`,
    config
  );

  return response.data;
};

const getPropertiesOnVerificationByUser = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_URL}/properties/user/verifying_property/${id}`,
    config
  );

  console.log(response.data);

  return response.data;
};
const getAllProperties = async () => {
  const response = await axios.get(`${API_URL}/properties`);

  if (response.data) {
    localStorage.setItem("properties", JSON.stringify(response.data));
  }

  return response.data;
};

const authService = {
  addProperties,
  getAllProperties,
  getPropertiesByUser,
  getArchivedPropertiesByUser,
  getEscrowPropertiesByUser,
  getPropertiesOnVerificationByUser,
  addInitializePropertyValidation,
};

export default authService;
