import React, { useEffect } from "react";
import FindlandBanner from "../components/FindLand/FindlandBanner";
import PropertyListings from "../components/FindLand/PropertyListings";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getAllProperties } from "../features/property/propertySlice";
import { useLocation, useParams } from "react-router-dom";
import { getAllLands } from "../features/land/landSlice";
import SearchForm from "../components/SearchForm";
import { Container } from "@mui/material";

const SearchLand = () => {
  const dispatch = useDispatch();
  const { route } = useParams();
  const location = useLocation();
  const propValue = location?.state?.values;

  const { property: properties } = useSelector((state) => state.property);
  const { land: lands } = useSelector((state) => state.land);

  useEffect(() => {
    dispatch(getAllProperties());
    dispatch(getAllLands());
  }, [location.pathname]);

  var filteredProperties = [];
  if (propValue?.Category === "building") {
    filteredProperties = properties?.filter(
      (property) =>
        (property?.Country?.toLowerCase().includes(
          propValue.Country.toLowerCase()
        ) &&
          property?.State?.toLowerCase().includes(
            propValue.State.toLowerCase()
          )) ||
        property?.Area?.toLowerCase().includes(propValue.Area.toLowerCase())
    );
  } else {
    filteredProperties = lands?.filter(
      (property) =>
        (property?.Country?.toLowerCase().includes(
          propValue.Country.toLowerCase()
        ) &&
          property?.State?.toLowerCase().includes(
            propValue.State.toLowerCase()
          )) ||
        property?.Area?.toLowerCase().includes(propValue.Area.toLowerCase())
    );
  }

  return (
    <>
      <Navbar />
      <Container>
        <SearchForm />
      </Container>
      <PropertyListings properties={filteredProperties} />
      <Footer />
    </>
  );
};

export default SearchLand;
