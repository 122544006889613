import axios from "axios";
import { API_URL } from "../api";

//Register user
const register = async (userData) => {
  const response = await axios.post(`${API_URL}/users`, userData);

  return response.data;
};

const addProperties = async (values) => {
  const response = await axios.post(`${API_URL}/properties`, values);
  return response.data;
};

//verify

const verify = async (data) => {
  const response = await axios.get(
    `${API_URL}/users/verify/${data.id}/${data.string}`
  );

  if (response.data) {
    localStorage.setItem("lyl-user", JSON.stringify(response.data));
  }

  return response.data;
};

//logout

const logout = async () => {
  localStorage.removeItem("lyl-user");
};

export const logoutError = () => {
  localStorage.removeItem("lyl-user");
};

//login

const login = async (userData) => {
  const response = await axios.post(`${API_URL}/users/login`, userData);

  if (response.data) {
    localStorage.setItem("lyl-user", JSON.stringify(response.data));
  }
  return response.data;
};

//forgot Password
const forgotPassword = async (userData) => {
  const response = await axios.post(
    `${API_URL}/users/forgot_password`,
    userData
  );
  return response.data;
};

//update

const update = async (userData, userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/users/${userId}/update_profile`,
    userData,
    config
  );

  if (response.data) {
    localStorage.setItem("lyl-user", JSON.stringify(response.data));
  }

  return response.data;
};

////update Password

const updatePassword = async (userData, userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}${userId}/update_password`,
    userData,
    config
  );

  return response.data;
};

const authService = {
  addProperties,
  register,
  update,
  logout,
  verify,
  login,
  updatePassword,
  forgotPassword,
};

export default authService;
