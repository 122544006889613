import React from "react";
import Box from "@mui/system/Box";
import { Typography, Container, Button, Stack } from "@mui/material";

import { Link } from "react-router-dom";

import Zenith from "../../assets/first.png";
// import Zenith from "../../assets/Zenith-Bank-Logo.jpg";
import { useSelector } from "react-redux";
import SearchForm from "../SearchForm";

const Lockyourland = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Box component="div" className="banner">
      <Container className="sm:py-20 py-12">
        <Box component="div">
          <Typography variant="h4">
            {" "}
            <span className="text-green-600 font-bold sm:text-5xl text-md">
              Lock
            </span>{" "}
            <span className="text-blue-800 font-bold sm:text-5xl text-md">
              Your Land
            </span>
          </Typography>
          <Typography variant="h4">
            Buy, Secure And Sell your Property
          </Typography>
          <Typography
            variant="subtitle2"
            className=" sm:w-2/4 w-100"
            style={{
              lineHeight: "1.3rem",
              marginTop: ".5%",
              fontSize: "12px",
              fontWeight: "600",
              opacity: "90%",
            }}>
            We ensure that all your transactions are smooth when you're looking
            to buy, sell or hold unto your realestates properties. No more loss,
            no more fraud, no more omo onile games. You can rest easy knowing
            your money is safe till your land is secured
          </Typography>
          {/* <Box
            component="div"
            className="w-64 border border-blue-800 my-4 rounded-xl flex"
          >
            <Box component="div" className="p-2 rounded-xl bg-blue-800">
              <Typography
                variant="body2"
                className="text-white"
                sx={{ fontSize: "11px" }}
              >
                Powered And <br />
                Secured By
              </Typography>
            </Box>
            <Box
              component="div"
              className="w-2/3 flex items-center justify-center"
            >
              <img src={Zenith} alt="Stanbic Ibtc image" width="80%" />
            </Box>
          </Box> */}
          <Stack spacing={2} direction="row" className="mt-8">
            <Link to={`${user ? "buyLand" : "Login"}`}>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#1e40af",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                className="bg-blue-500"
                size="medium">
                Escrow Now
              </Button>
            </Link>
            <Link to="findLand/LandsListing">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  bgcolor: "#4ade80",
                  color: "#000",
                  textTransform: "none",
                  fontWeight: "bold",
                }}>
                Check Properties
              </Button>
            </Link>
          </Stack>
          {/* Select Form */}
          <SearchForm />
        </Box>
      </Container>
    </Box>
  );
};

export default Lockyourland;
