import React from "react";
import notfoundImg from "../../assets/notfound.svg";
import deniedImg from "../../assets/access.svg";
import { Box, Typography } from "@mui/material";
const NotFound = ({ type }) => {
  return (
    <Box>
      <Box className="flex my-5 items-center justify-center">
        <img
          src={type == "denied" ? deniedImg : notfoundImg}
          alt="notfound"
          className="w-60 sm:w-100"
        />
      </Box>
      <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
        {type && type === "notfound" ? (
          "No property was identified."
        ) : type && type === "denied" ? (
          "Acess denied for this property."
        ) : type && type === "notValidated" ? (
          <Typography>
            {" "}
            This Property has not been validated by the{" "}
            <span className="text-green-600 font-bold sm:text-xl text-md">
              Lock.
            </span>
            <span className="font-bold text-neutral-900 sm:text-xl text-md">
              your
            </span>
            <span className="text-blue-800 font-bold sm:text-xl text-md">
              .land
            </span>{" "}
            legal team{" "}
          </Typography>
        ) : (
          "No property was identified."
        )}
      </Typography>
    </Box>
  );
};

export default NotFound;
