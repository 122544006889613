import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import completeSvg from "../../assets/completeSVG.svg";
import searchSvg from "../../assets/search.svg";
import verifiedImg from "../../assets/verified.png";
import {
  finalizePropertyVerification,
  initializePropertyVerification,
  reset,
} from "../../features/verify_documents/verify_documents_slice";
import { url } from "../../utils/Index";
import PaymentModal from "../addons/PaymentModal";
import ReportModal from "../addons/ReportModal";
import InitiateDocumentSearchAndSurvey from "../Land_Property_Sidebar_Actions/InitiateDocumentSearchAndSurvey";
import DisplaySearchAndSurveyDocumentForProperties from "../Land_Property_Sidebar_Actions/DisplaySearchAndSurveyDocumentForProperties";
import InitiateFinalPayment from "../Land_Property_Sidebar_Actions/InitiateFinalPayment";

const PropertyInfoSideBar = ({ property, user }) => {
  const [handleOpenPaymentModal, setOpenPaymentModal] = useState(false);
  const { isLoading, isSuccess, isError, message, response } = useSelector(
    (state) => state.documents
  );
  const { land } = useSelector((state) => state.singleLand);

  const dispatch = useDispatch();

  const handleVerify = (reference) => {
    const propertyId = property?.properties?._id;
    const purpose = "Verification";
    dispatch(
      initializePropertyVerification({ propertyId, purpose, reference })
    );
    // dispatch(initializePropertSurvey({ propertyId }));
  };

  const handleFinalPaymentVerification = (reference) => {
    const propertyId = property?.properties?._id;
    const docId = property?.document?._id;
    dispatch(finalizePropertyVerification({ propertyId, docId, reference }));
  };

  useEffect(() => {
    if (isError) {
      setOpenPaymentModal(false);
      Swal.fire({
        icon: "error",
        title: "Ooops",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        dispatch(reset());
        setOpenPaymentModal(false);
      });
    }

    if (isSuccess) {
      setOpenPaymentModal(false);
      Swal.fire({
        icon: "success",
        title: "",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        window.location.reload(false);
        dispatch(reset());
        setOpenPaymentModal(false);
      });
    }
  }, [isError, isSuccess, message, dispatch]);

  const [openReportModal, setOpenReportModal] = useState(false);
  return (
    <Grid item xs={12} sm={12} md={12} lg={4}>
      <Box
        component="div"
        className=" bg-gray-100 rounded-lg p-3 mt-1 "
        sx={{ height: "78vh" }}>
        <div className="flex items-center justify-center">
          <img
            src={
              property?.document?.Status === "completed"
                ? completeSvg
                : searchSvg
            }
            alt="Complete SVG"
            className="h-[20vh]"
          />
        </div>

        {property?.properties?.UserId !== user?._id ||
        (property?.properties?.UserId === user?._id &&
          property?.properties?.Listings === false) ? (
          <div className="sm:mt-10 mt-5">
            <div className="mt-5">
              <Stack spacing={2} direction="column">
                <>
                  {(property?.document?.SurveyStatus === "pending" &&
                    property?.document?.SurveyMessageStatus === false) ||
                  (property?.document?.Status === "pending" &&
                    property?.document?.ValidationMessageStatus === false) ? (
                    <>
                      <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                        We're actively conducting searches and survey for this
                        property. Thank you for your patience; we'll keep you
                        updated
                      </Typography>

                      <Button
                        variant="contained"
                        color="info"
                        disableElevation
                        size="medium"
                        sx={{
                          fontSize: "11px",
                          width: "100%",
                          textTransform: "none",
                        }}>
                        Search/Survey in Progress
                      </Button>
                    </>
                  ) : (property?.document?.SurveyStatus === "completed" ||
                      property?.document?.Status === "completed") &&
                    property?.document?.UnbalancedPaymentStatus === true ? (
                    <DisplaySearchAndSurveyDocumentForProperties
                      property={property}
                      document={document}
                      setOpenReportModal={setOpenPaymentModal}
                      openReportModal={openReportModal}
                    />
                  ) : (land?.document?.SurveyStatus === "completed" ||
                      land?.document?.Status === "completed") &&
                    land?.document?.UnbalancedPaymentStatus === false ? (
                    <InitiateFinalPayment
                      isLoading={isLoading}
                      handleOpenPaymentModal={handleOpenPaymentModal}
                      handleFinalPaymentVerification={
                        handleFinalPaymentVerification
                      }
                      setOpenPaymentModal={setOpenPaymentModal}
                      document={property?.document}
                    />
                  ) : (
                    <InitiateDocumentSearchAndSurvey
                      isLoading={isLoading}
                      handleOpenPaymentModal={handleOpenPaymentModal}
                      handleVerify={handleVerify}
                      setOpenPaymentModal={setOpenPaymentModal}
                    />
                  )}
                </>
              </Stack>
            </div>
          </div>
        ) : (
          ""
        )}
      </Box>
    </Grid>
  );
};

export default PropertyInfoSideBar;
