import React from "react";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import SearchForm from "../SearchForm";

const FindlandBanner = ({ route }) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const inputsLabel = [
    {
      InputTitle: "Location",
      Values: ["Benin", "Lagos", "Abuja"],
    },
    {
      InputTitle: "Area",
      Values: ["Ekehuan", "Ikeja", "Lekki"],
    },
    {
      InputTitle: "Property Type",
      Values: ["Land", "Bongalow", "Duplex"],
    },
    {
      InputTitle: "Purpose",
      Values: ["Rent", "Sale", "Escrow"],
    },
    {
      InputTitle: "Prize",
      Values: ["1million to 2million", "10million to 15million"],
    },
  ];
  return (
    <Box component="div" className="text-center">
      <Container className="sm:py-10 py-12">
        <Box component="div" className="findlandBanner">
          <div className="text-center styled-p">
            <Typography variant="h4">
              {" "}
              <span className="text-green-600 font-bold sm:text-5xl text-md">
                Find Your
              </span>{" "}
              <span className="text-blue-800 font-bold sm:text-5xl text-md">
                {route === "LandsListing" ? "Land" : "Property"}
              </span>
            </Typography>
            <Typography variant="h6" sx={{ marginTop: "2%" }}>
              <span className="font-bold">Simple, Fast, Secured.</span> Peace of
              mind in three steps: Search,Select and Lock.your.Land
            </Typography>
            <Typography
              variant="subtitle2"
              className=""
              style={{
                lineHeight: "1.3rem",
                fontSize: "13px",
                opacity: "90%",
                marginTop: "2%",
              }}>
              Orange island is your next home, when you escrow with us you
              decide what happens to your land whether you are trading or locing
            </Typography>
          </div>

          {/* Select Form */}
          <Box
            component="div"
            className=" bg-white py-3 sm:px-12 px-3 border-2 mt-60 border-blue-800 rounded-lg  m-auto">
            <SearchForm route="findLand" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FindlandBanner;
