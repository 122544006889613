import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { SearchPrices, SearchSchema } from "../utils/Index";
import { useNavigate } from "react-router-dom";

const SearchForm = ({ route }) => {
  const navigate = useNavigate();
  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      Country: "",
      State: "",
      Area: "",
      Category: "",
      Price: "",
    },
    validationSchema: SearchSchema,
    onSubmit: (values, actions) => {
      navigate("/searchLand", { state: { values } });
    },
  });
  return (
    <Box
      component="div"
      className={`py-3 sm:px-12 px-3 ${
        route === "findLand" ? null : "mt-32 border-2  border-blue-800"
      }  rounded-lg  m-auto`}>
      <Box
        onSubmit={handleSubmit}
        component="form"
        sx={{
          "& .MuiTextField-root": { width: "100%" },
          padding: 0,
        }}
        noValidate
        autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={2}>
            <TextField
              label="Country"
              Placeholder="Nigeria..."
              id="Country"
              onChange={handleChange}
              InputLabelProps={{
                style: { fontSize: "12px" },
              }}
              value={values.Country}
              onBlur={handleBlur}
              inputProps={{ style: { fontSize: 12 } }}
            />
            {errors.Country && touched.Country && (
              <Typography sx={{ fontSize: "10px", color: "red" }}>
                {errors.Country}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <TextField
              label="State"
              Placeholder="Nigeria..."
              id="State"
              onChange={handleChange}
              InputLabelProps={{
                style: { fontSize: "12px" },
              }}
              value={values.State}
              onBlur={handleBlur}
              inputProps={{ style: { fontSize: 12 } }}
            />
            {errors.State && touched.State && (
              <Typography sx={{ fontSize: "10px", color: "red" }}>
                {errors.State}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <TextField
              label="Area"
              Placeholder="Nigeria..."
              id="Area"
              onChange={handleChange}
              InputLabelProps={{
                style: { fontSize: "12px" },
              }}
              value={values.Area}
              onBlur={handleBlur}
              inputProps={{ style: { fontSize: 12 } }}
            />
            {errors.Area && touched.Area && (
              <Typography sx={{ fontSize: "10px", color: "red" }}>
                {errors.Area}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontSize: "12px" }}>
                Category
              </InputLabel>
              <Select
                labelId="Category"
                size="medium"
                id="Category"
                label="Category"
                InputLabelProps={{
                  style: { fontSize: "12px" },
                }}
                sx={{ fontSize: "12px" }}
                value={values.Category}
                onChange={(e) => {
                  setFieldValue("Category", e.target.value);
                }}
                onBlur={(e) => {
                  setFieldValue("Category", e.target.value);
                }}>
                <MenuItem value="land" sx={{ fontSize: "12px" }}>
                  Landed Properties
                </MenuItem>
                <MenuItem value="building" sx={{ fontSize: "12px" }}>
                  Building
                </MenuItem>
              </Select>
            </FormControl>
            {errors.Category && (
              <Typography sx={{ fontSize: "10px", color: "red" }}>
                {errors.Category}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ fontSize: "12px" }}>
                Price
              </InputLabel>
              <Select
                labelId="Price"
                id="Price"
                label="Price"
                InputLabelProps={{
                  style: { fontSize: "12px" },
                }}
                sx={{ fontSize: "12px" }}
                value={values.Price}
                onChange={(e) => {
                  setFieldValue("Price", e.target.value);
                }}
                onBlur={(e) => {
                  setFieldValue("Price", e.target.value);
                }}>
                {SearchPrices.map((Price) => (
                  <MenuItem
                    key={Price.value}
                    value={Price.value}
                    sx={{ fontSize: "12px" }}>
                    {Price.output}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.Price && (
              <Typography sx={{ fontSize: "10px", color: "red" }}>
                {errors.Price}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#4ade80",
                textTransform: "none",
                fontWeight: "bold",
                width: "100%",
              }}
              className="bg-green-500"
              type="submit">
              Start Now
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SearchForm;
