import React from "react";

import LandInfo from "../components/LandOwner/LandInfo";
import Navbar from "../components/Navbar";

const LandDetails = () => {
  return (
    <>
      <Navbar />
      <LandInfo />
    </>
  );
};

export default LandDetails;
