import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import propertyService from "./propertyService";
import { logoutError } from "../auth/authService";
import { createAsyncThunkWithHandler } from "../api";

const property = JSON.parse(localStorage.getItem("property"));

const initialState = {
  property: property ? property : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getSingleProperties = createAsyncThunkWithHandler(
  "property/getSingleProperties",
  async (propertyId, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    return await propertyService.getSingleProperties(propertyId, userId, token);
  }
);

export const upadteSingleProperties = createAsyncThunkWithHandler(
  "property/upadteSingleProperties",
  async (property, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const propertyData = property.formData;
    const propertyId = property.propertyId;
    return await propertyService.updateSingleProperty(
      propertyData,
      propertyId,
      token
    );
  }
);

export const archiveProperty = createAsyncThunkWithHandler(
  "property/archiveProperty",
  async (propertyId, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    return await propertyService.archiveProperty(propertyId, token);
  }
);

export const deleteProperty = createAsyncThunkWithHandler(
  "property/deleteProperty",
  async (propertyId, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    return await propertyService.deleteProperty(propertyId, token);
  }
);

export const unarchiveProperty = createAsyncThunkWithHandler(
  "property/unarchiveProperty",
  async (propertyId, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    return await propertyService.unarchiveProperty(propertyId, token);
  }
);

export const single_propertySlice = createSlice({
  name: "singleProperty",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getSingleProperties.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleProperties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = action.payload;
      })
      .addCase(getSingleProperties.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
      })
      .addCase(upadteSingleProperties.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(upadteSingleProperties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = action.payload;
        state.message = "Property Updated Successfully";
      })
      .addCase(upadteSingleProperties.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
      })
      .addCase(archiveProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(archiveProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = action.payload;
        state.message = "This property has been archived successfully";
      })
      .addCase(archiveProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
      })
      .addCase(unarchiveProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unarchiveProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = action.payload;
        state.message = "This property has been activated successfully";
      })
      .addCase(unarchiveProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
      })
      .addCase(deleteProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.property = action.payload;
        state.message = "This property has been deleted successfully";
      })
      .addCase(deleteProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.property = null;
      });
  },
});

export const { reset } = single_propertySlice.actions;
export default single_propertySlice.reducer;
