import React, { useEffect } from "react";
import DashboardNavbar from "../components/DashboardNavbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Link } from "react-router-dom";
import UserDetails from "../components/userInformation/UserDetails";
import { useSelector } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";
import useSaveDraft from "../hooks/form-hooks/local-databse/useSaveDraft";

const DashboardInitiateVerify = () => {
  const { user } = useSelector((state) => state.auth);
  const { loadAllDrafts, drafts } = useSaveDraft();

  useEffect(() => {
    loadAllDrafts();
  }, []);

  return (
    <>
      <DashboardNavbar />
      <Box component="div" sx={{ height: "90vh" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <UserDetails />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box
                component="div"
                className="sm:p-5 p-5  bg-green-custom"
                sx={{ height: "90vh" }}>
                <Box component="div" className="p-3 mt-5 sm:mt-0  w-full">
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      opacity: "90%",
                    }}>
                    Verify your documents
                  </Typography>
                  {user?.VerifiedUser != "completed" && (
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "11px",
                        opacity: "90%",
                        color: "red",
                      }}>
                      Hi, Please Update your profile to sell a property
                    </Typography>
                  )}
                  <Box className="grid sm:grid-cols-3 grid-cols-2 gap-4">
                    <Link
                      to={`${
                        user?.VerifiedUser != "completed"
                          ? ""
                          : "/verifyPropertyForm"
                      } `}>
                      <div
                        className={`border  rounded-lg h-20  p-3 text-center justify-center items-center flex space-x-2 mt-5 bg-gray-300 border-gray-300`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                          }}>
                          Built Properties
                        </span>{" "}
                        {user?.VerifiedUser != "completed" ? (
                          <LockIcon fontSize="small" />
                        ) : (
                          <AddCircleOutlineIcon fontSize="small" />
                        )}
                      </div>
                    </Link>
                    <Link
                      to={`${
                        user?.VerifiedUser != "completed"
                          ? ""
                          : "/verifyLandForm"
                      } `}>
                      <div
                        className={`border  rounded-lg h-20  p-3 text-center justify-center items-center flex space-x-2 mt-5  bg-gray-300 border-gray-300`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                          }}>
                          Landed Properties
                        </span>{" "}
                        {user?.VerifiedUser != "completed" ? (
                          <LockIcon fontSize="small" />
                        ) : (
                          <AddCircleOutlineIcon fontSize="small" />
                        )}
                      </div>
                    </Link>
                    {drafts?.length > 0 && (
                      <Link to="/dashboard/drafts">
                        <div
                          className={`border  rounded-lg h-20  p-2 text-center justify-center items-center flex space-x-2 mt-5  bg-blue-700 border-blue-700`}
                          style={{ border: "1px dotted gray" }}>
                          <span
                            style={{
                              fontSize: "11px",

                              fontWeight: "bold",
                              color: "#fff",
                            }}>
                            Your Drafts
                          </span>{" "}
                          {user?.VerifiedUser != "completed" ? (
                            <LockIcon fontSize="small" />
                          ) : (
                            <AddCircleOutlineIcon fontSize="small" />
                          )}
                        </div>
                      </Link>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3} className="sm:block hidden">
              <Box
                component="div"
                className=" rounded-lg p-3 mt-5 "
                sx={{ height: "78vh" }}>
                <div className="flex justify-between items-center">
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      opacity: "90%",
                    }}>
                    Notifications
                  </Typography>
                  <NotificationsActiveIcon sx={{ color: "#22c55e" }} />
                </div>
                {/* <div>
                  <Notification />
                </div> */}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DashboardInitiateVerify;
