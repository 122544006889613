import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LockIcon from "@mui/icons-material/Lock";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import UserDetails from "../userInformation/UserDetails";
import useSaveDraft from "../../hooks/form-hooks/local-databse/useSaveDraft";

const DashboardBuySellEscrow = ({ Title, bg, border, target }) => {
  const { user } = useSelector((state) => state.auth);
  const { loadAllDrafts, drafts } = useSaveDraft();

  useEffect(() => {
    loadAllDrafts();
  }, []);
  return (
    <Box component="div" sx={{ height: "90vh" }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3}>
            <UserDetails />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              component="div"
              className="sm:p-5 p-5  bg-green-custom"
              sx={{ height: "90vh" }}>
              <Box component="div" className="p-3 mt-5 sm:mt-0  w-full">
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", fontSize: "16px", opacity: "90%" }}>
                  Dashboard
                </Typography>
                {user?.VerifiedUser != "completed" && (
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "11px",
                      opacity: "90%",
                      color: "red",
                    }}>
                    Hi, Please Update your profile to sell a property
                  </Typography>
                )}
                {user?.VerifiedUser != "completed" ? (
                  <Box className="flex items-center space-x-2 w-full">
                    <div
                      className={`border py-8 cursor-pointer rounded-lg px-5   text-center justify-center items-center flex space-x-2 mt-5 ${bg} ${border}`}
                      style={{ border: "1px dotted gray" }}>
                      <span style={{ fontSize: "11px", fontWeight: "bold" }}>
                        {Title} Land
                      </span>{" "}
                      <LockIcon fontSize="small" />
                    </div>

                    <div
                      className={`border py-8 cursor-pointer rounded-lg px-5   text-center justify-center items-center flex space-x-2 mt-5 ${bg} ${border}`}
                      style={{ border: "1px dotted gray" }}>
                      <span style={{ fontSize: "11px", fontWeight: "bold" }}>
                        {Title} Building
                      </span>{" "}
                      <LockIcon fontSize="small" />
                    </div>
                  </Box>
                ) : (
                  <Box className="grid sm:grid-cols-3 grid-cols-2 gap-4">
                    <Link to={`${target}LandForm`}>
                      <div
                        className={`border py-8 cursor-pointer rounded-lg px-5   text-center justify-center items-center flex space-x-2 mt-5 ${bg} ${border}`}
                        style={{ border: "1px dotted gray" }}>
                        <span style={{ fontSize: "11px", fontWeight: "bold" }}>
                          {Title} Land
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                    <Link to={`${target}PropertyForm`}>
                      <div
                        className={`border py-8 cursor-pointer rounded-lg px-5   text-center justify-center items-center flex space-x-2 mt-5 ${bg} ${border}`}
                        style={{ border: "1px dotted gray" }}>
                        <span style={{ fontSize: "11px", fontWeight: "bold" }}>
                          {Title} Building
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                    {drafts?.length > 0 && (
                      <Link to="/dashboard/drafts">
                        <div
                          className={`border  rounded-lg h-20  p-2 text-center justify-center items-center flex space-x-2 mt-5  bg-blue-700 border-blue-700`}
                          style={{ border: "1px dotted gray" }}>
                          <span
                            style={{
                              fontSize: "11px",

                              fontWeight: "bold",
                              color: "#fff",
                            }}>
                            Your Drafts
                          </span>{" "}
                          <AddCircleOutlineIcon fontSize="small" />
                        </div>
                      </Link>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Box
              component="div"
              className=" rounded-lg p-3 mt-5 "
              sx={{ height: "78vh" }}>
              <div className="flex justify-between items-center">
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", fontSize: "16px", opacity: "90%" }}>
                  Notifications
                </Typography>
                <NotificationsActiveIcon sx={{ color: "#22c55e" }} />
              </div>
              {/* <div>
                {Notifications.map((notify, index) => {
                  return (
                    <div
                      className="py-3 rounded-lg bg-green-custom px-2 my-3"
                      key={index}
                    >
                      <Typography variant="subtitle1" sx={{ fontSize: "13px" }}>
                        {notify.Text}
                      </Typography>
                      <Typography variant="subtitle2" sx={{ opacity: "80%" }}>
                        {notify.Time}
                      </Typography>
                    </div>
                  );
                })}
              </div> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DashboardBuySellEscrow;
