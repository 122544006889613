import MonoConnect from "@mono.co/connect.js";
import {
  Bed,
  Chair,
  FamilyRestroom,
  TableRestaurant,
} from "@mui/icons-material";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState } from "react";
import { ImageViewer } from "react-image-viewer-dv";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  archiveProperty,
  deleteProperty,
  getSingleProperties,
  reset,
  unarchiveProperty,
} from "../../features/single_property/propertySlice";
import LandOwnerDetails from "./LandOwnerDetails";

import PropertyInfoSideBar from "./PropertyInfoSideBar";

const LandInfo = ({ validity }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();

  useEffect(() => {
    dispatch(getSingleProperties(propertyId));
  }, []);

  const { property, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.singleProperty
  );

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      dispatch(reset());
      setLoading(false);
    }, 3000);
  }, []);

  const unarchivePropertyController = () => {
    Swal.fire({
      title: "Do you want to Re-activate this Property?",
      showCancelButton: true,
      confirmButtonText: "Activate",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(unarchiveProperty(propertyId));
      }
    });
  };

  const archivePropertyController = () => {
    Swal.fire({
      title: "Do you want to archive this Property?",
      showCancelButton: true,
      confirmButtonText: "Archive",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(archiveProperty(propertyId));
      }
    });
  };
  const deletePropertyController = () => {
    Swal.fire({
      title: "Do you want to delete this Property?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProperty(propertyId));
      }
    });
  };

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Ooops",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        dispatch(reset());
        window.location.reload(false);
      });
    }

    if (
      isSuccess &&
      (message.includes("This property has been activated successfully") ||
        message.includes("This property has been archived successfully") ||
        message.includes("This property has been deleted successfully"))
    ) {
      Swal.fire({
        icon: "success",
        title: "Congratulations",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        navigate(`/dashboard`);
        dispatch(reset());
      });
    }
  }, [isError, isSuccess, message, dispatch]);

  const specialProperties = [
    {
      Title: "Sitting Rooms",
      Icon: <Chair className="text-green-500" />,
      Number: property?.properties?.SittingRooms,
    },
    {
      Title: "Bed Rooms",
      Icon: <Bed className="text-green-500" />,
      Number: property?.properties?.BedRooms,
    },
    {
      Title: "Rest Rooms",
      Icon: <FamilyRestroom className="text-green-500" />,
      Number: property?.properties?.RestRooms,
    },
    {
      Title: "Other Room",
      Icon: <TableRestaurant className="text-green-500" />,
      Number: property?.properties?.OtherRooms,
    },
  ];

  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => console.log("Widget closed"),
      onLoad: () => console.log("Widget loaded successfully"),
      onSuccess: ({ code }) => console.log(`Linked successfully: ${code}`),
      key: "test_pk_tx6qf04h8o3ncyvbyufz",
    });

    monoInstance.setup();

    return monoInstance;
  }, []);
  return (
    <Box component="div" sx={{ height: "90vh" }}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={3} md={2}>
            <LandOwnerDetails details={property?.OwnerDetails} />
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            <Box
              component="div"
              className="sm:p-5 p-5  bg-green-custom"
              sx={{ height: "90vh" }}>
              {loading ? (
                <Box className="flex items-center justify-center">
                  <Skeleton
                    variant="rectangular"
                    width={210}
                    height={20}
                    style={{
                      marginBottom: 6,
                      marginTop: 10,
                    }}
                  />
                </Box>
              ) : (
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "1%",
                    textAlign: "center",
                  }}>
                  Property Details
                </Typography>
              )}

              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                  <Box
                    sx={{ width: "100%" }}
                    component="div"
                    className=" styled-border p-3">
                    {loading ? (
                      <Box>
                        {property?.properties?.Images?.map((item) => (
                          <Skeleton animation="wave" height={100} width="80%" />
                        ))}
                      </Box>
                    ) : (
                      <>
                        <ImageList variant="masonry" cols={2} gap={10}>
                          {property?.properties?.Images?.map((item, index) => (
                            <ImageListItem key={index}>
                              <ImageViewer>
                                <img
                                  src={item}
                                  srcSet={item}
                                  alt="House photos"
                                  loading="lazy"
                                  className="rounded-lg h-[15vh]"
                                />
                              </ImageViewer>
                            </ImageListItem>
                          ))}
                        </ImageList>
                        {user &&
                          user?._id === property?.properties?.UserId &&
                          property?.properties?.Listings === true && (
                            <Box className="flex items-center space-x-2 mt-3">
                              <Link
                                to={`/editProperty/${property?.properties?._id}`}>
                                <Button
                                  size="small"
                                  sx={{ fontSize: "9px" }}
                                  disableElevation
                                  variant="contained"
                                  endIcon={
                                    <EditIcon sx={{ fontSize: "5px" }} />
                                  }>
                                  Edit
                                </Button>
                              </Link>
                              {isLoading ? (
                                <>
                                  <Button
                                    size="small"
                                    sx={{ fontSize: "9px" }}
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                    endIcon={
                                      <ArchiveIcon sx={{ fontSize: "5px" }} />
                                    }
                                    onClick={
                                      property?.properties?.Archive === true
                                        ? unarchivePropertyController
                                        : archivePropertyController
                                    }>
                                    {property?.properties?.Archive === true
                                      ? "Re-activate"
                                      : "Archive"}
                                  </Button>
                                  <Button
                                    sx={{ fontSize: "9px" }}
                                    size="small"
                                    disableElevation
                                    color="error"
                                    disabled
                                    variant="contained"
                                    startIcon={
                                      <DeleteIcon sx={{ fontSize: "5px" }} />
                                    }>
                                    Delete
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    size="small"
                                    disableElevation
                                    variant="contained"
                                    sx={{ fontSize: "9px" }}
                                    color="secondary"
                                    endIcon={
                                      <ArchiveIcon sx={{ fontSize: "5px" }} />
                                    }
                                    onClick={
                                      property?.properties?.Archive === true
                                        ? unarchivePropertyController
                                        : archivePropertyController
                                    }>
                                    {property?.properties?.Archive === true
                                      ? "Re-activate"
                                      : "Archive"}
                                  </Button>
                                  <Button
                                    size="small"
                                    disableElevation
                                    sx={{ fontSize: "9px" }}
                                    color="error"
                                    variant="contained"
                                    startIcon={
                                      <DeleteIcon sx={{ fontSize: "5px" }} />
                                    }
                                    onClick={deletePropertyController}>
                                    Delete
                                  </Button>
                                </>
                              )}
                            </Box>
                          )}
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={9}>
                  {loading ? (
                    <Grid container className="px-5">
                      <Grid item xs={12} sm={6} md={6}>
                        <Skeleton animation="wave" height={300} width="80%" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Skeleton animation="wave" height={300} width="80%" />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Box component="div" className="p-3 mt-5 sm:mt-0">
                          <div className="">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Location
                            </Typography>
                            <Typography
                              variant="h6"
                              className={`${user ? null : "blur-sm"}`}
                              sx={{ fontWeight: "bold", fontSize: "15px" }}>
                              {property?.properties?.Country},{" "}
                              {property?.properties?.State},{" "}
                              {property?.properties?.Area},{" "}
                              {property?.properties?.Address}
                            </Typography>
                          </div>
                          <div className="mt-2">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Unique Id of Building
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "15px" }}>
                              {property?.properties?.UniqueId}
                            </Typography>
                          </div>
                          <div className="mt-2">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Price of Land
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "15px" }}>
                              {property?.properties?.Curreny}{" "}
                              {property?.properties?.Price.toLocaleString()}
                            </Typography>
                          </div>
                          <div className="mt-2">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Escrow Price of Land
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "15px" }}>
                              {property?.properties?.Curreny}{" "}
                              {property?.properties?.Escrow.toLocaleString()}
                            </Typography>
                          </div>
                          <div
                            className="mt-5 py-2"
                            style={{
                              borderTop: "1px dotted gray",
                              borderBottom: "1px dotted gray",
                            }}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Size of Property
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "15px" }}>
                              {property?.properties?.Measurement}{" "}
                              {property?.properties?.Size}
                            </Typography>
                          </div>
                          <div className="mt-2 py-1">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Special Properties
                            </Typography>
                            <div
                              className={`flex justify-between  ${
                                user ? null : "blur-sm"
                              }`}>
                              {specialProperties.map((item, index) => {
                                const { Icon } = item;
                                return (
                                  <div
                                    key={index}
                                    className="flex items-center flex-col ">
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontSize: "11px",
                                        fontWeight: "bold",
                                      }}>
                                      {item.Title}
                                    </Typography>
                                    <div className="rounded-full p-2 border mt-2 bg-gray-100">
                                      {Icon}
                                    </div>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                      }}>
                                      {item.Number}
                                    </Typography>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Box>
                      </Grid>
                      <PropertyInfoSideBar property={property} user={user} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandInfo;
