import React from "react";

import LandInfo from "../components/LandOwner/LandInfo";
import Navbar from "../components/DashboardNavbar";

const DashboardLandDetails = () => {
  return (
    <>
      <Navbar />
      <LandInfo validity={true} />
    </>
  );
};

export default DashboardLandDetails;
