import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkWithHandler } from "../api";
import documentService from "./check_document_originality_service";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: null,
  response: null,
};

export const checkValidity = createAsyncThunkWithHandler(
  "auth/checkValidity",
  async (uniqueId, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    return await documentService.validateDocument({
      uniqueId: uniqueId,
      userId: userId,
      token: token,
    });
  }
);

export const getDocument = createAsyncThunkWithHandler(
  "auth/getDocument",
  async (value, thunkAPI) => {
    const userId = thunkAPI.getState().auth.user._id;
    const token = thunkAPI.getState().auth.user.token;
    return await documentService.getDocument({
      value,
      userId: userId,
      token: token,
    });
  }
);

const checkValiditySlice = createSlice({
  name: "validate",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkValidity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkValidity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Property Validation returned";
        state.response = action.payload;
      })
      .addCase(checkValidity.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.response = null;
      })
      .addCase(getDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocument.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Property Validation returned";
        state.response = action.payload;
      })
      .addCase(getDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.response = null;
      });
  },
});

export const { reset } = checkValiditySlice.actions;
export default checkValiditySlice.reducer;
