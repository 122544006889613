import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkWithHandler } from "../api";
import landService from "./landService";
const initialState = {
  land: null,
  landsByUser: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addLand = createAsyncThunkWithHandler(
  "land/addLand",
  async (land, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const UserId = thunkAPI.getState().auth.user._id;
    return await landService.addLands(land, token, UserId);
  }
);

export const addInitializeLandValidation = createAsyncThunkWithHandler(
  "land/addInitializeLandValidation",
  async (land, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const UserId = thunkAPI.getState().auth.user._id;
    return await landService.addInitializeLandValidation(
      land.landData,
      land.reference,
      token,
      UserId
    );
  }
);

export const getAllLands = createAsyncThunkWithHandler(
  "land/getAllLands",
  async () => {
    return await landService.getAllLands();
  }
);

export const getLandsByUser = createAsyncThunkWithHandler(
  "land/getLandsByUser",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const id = thunkAPI.getState().auth.user._id;
    return await landService.getLandsByUser(token, id);
  }
);

export const getArchivedLandsByUser = createAsyncThunk(
  "land/getArchivedLandsByUser",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const id = thunkAPI.getState().auth.user._id;
    return await landService.getArchivedLandsByUser(token, id);
  }
);

export const getLandsOnVerificationByUser = createAsyncThunk(
  "land/getLandsOnVerificationByUser",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const id = thunkAPI.getState().auth.user._id;
    return await landService.getLandsOnVerificationByUser(token, id);
  }
);

export const landSlice = createSlice({
  name: "land",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addLand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addLand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = action.payload;
        state.landsByUser = null;
        state.message = "Property Added Successfully";
      })
      .addCase(addLand.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.land = null;
        state.landsByUser = null;
      })
      .addCase(addInitializeLandValidation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addInitializeLandValidation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = action.payload;
        state.message = "Congratulations you have added a land";
        state.landsByUser = null;
      })
      .addCase(addInitializeLandValidation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllLands.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLands.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = action.payload;
        state.landsByUser = null;
      })
      .addCase(getAllLands.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.land = null;
        state.landsByUser = null;
      })
      .addCase(getLandsByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLandsByUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = null;
        state.landsByUser = action.payload;
      })
      .addCase(getLandsByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.land = null;
        state.landsByUser = null;
      })
      .addCase(getArchivedLandsByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getArchivedLandsByUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = null;
        state.landsByUser = action.payload;
      })
      .addCase(getArchivedLandsByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.land = null;
        state.landsByUser = null;
      })
      .addCase(getLandsOnVerificationByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLandsOnVerificationByUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.land = null;
        state.landsByUser = action.payload;
      })
      .addCase(getLandsOnVerificationByUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.land = null;
        state.landsByUser = null;
      });
  },
});

export const { reset } = landSlice.actions;
export default landSlice.reducer;
