import axios from "axios";
import { API_URL } from "../api";

const validateDocument = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };

  const response = await axios.get(
    `${API_URL}/documents/verifydocs/check/${data.uniqueId}/${data.userId}`,

    config
  );
  return response.data;
};

const getDocument = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/documents/verifydocs/get/${data.value.uniqueId}/${data.userId}`,
    data.value,

    config
  );
  return response.data;
};

const documentService = {
  validateDocument,
  getDocument,
};

export default documentService;
