import React from "react";

import PropertyInfo from "../components/LandOwner/PropertyInfo";
import Navbar from "../components/DashboardNavbar";

const DashboardPropertyDetails = () => {
  return (
    <>
      <Navbar />
      <PropertyInfo validity={true} />
    </>
  );
};

export default DashboardPropertyDetails;
