import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Backdrop,
  Modal,
  Fade,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Import visibility icons
import { useFormik } from "formik";
import { UpdatePasswordSchema } from "../../utils/Index";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { reset, updatePassword } from "../../features/auth/authSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdatePasswordForm = () => {
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    setSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: UpdatePasswordSchema,
    onSubmit: (values, actions) => {
      const { oldPassword, newPassword, confirmNewPassword } = values;
      dispatch(
        updatePassword({ oldPassword, newPassword, confirmNewPassword })
      );
    },
  });

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          setSubmitting(false);
          dispatch(reset());
        },
      });
    }

    if (isSuccess && message == "Password updated successfully") {
      toast.success(message, {
        onClose: () => {
          setSubmitting(false);
          dispatch(reset());
        },
      });
    }
  }, [isError, isSuccess, message]);

  // Toggle password visibility
  const handleClickShowPassword = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  return (
    <Box className="mt-5">
      <div>
        <Button
          variant="contained"
          sx={{
            bgcolor: "#1e40af",
            textTransform: "none",
            fontWeight: "bold",
            marginTop: "3%",
          }}
          className="bg-blue-700"
          size="medium"
          type="submit"
          onClick={handleOpen}>
          Update Password
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}>
          <Fade in={open}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Box component="div">
                    <Box
                      onSubmit={handleSubmit}
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { width: "100%" },
                        padding: 0,
                        margin: "5% 0%",
                      }}
                      noValidate
                      autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            label="Old Password"
                            id="oldPassword"
                            size="medium"
                            type={
                              showPassword.oldPassword ? "text" : "password"
                            }
                            inputProps={{ style: { fontSize: 12 } }}
                            onChange={handleChange}
                            value={values.oldPassword}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleClickShowPassword("oldPassword")
                                    }>
                                    {showPassword.oldPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors.oldPassword && touched.oldPassword && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.oldPassword}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            label="New Password"
                            id="newPassword"
                            size="medium"
                            type={
                              showPassword.newPassword ? "text" : "password"
                            }
                            inputProps={{ style: { fontSize: 12 } }}
                            onChange={handleChange}
                            value={values.newPassword}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleClickShowPassword("newPassword")
                                    }>
                                    {showPassword.newPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors.newPassword && touched.newPassword && (
                            <Typography sx={{ fontSize: "9px", color: "red" }}>
                              {errors.newPassword}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            label="Confirm New Password"
                            id="confirmNewPassword"
                            size="medium"
                            type={
                              showPassword.confirmNewPassword
                                ? "text"
                                : "password"
                            }
                            inputProps={{ style: { fontSize: 12 } }}
                            onChange={handleChange}
                            value={values.confirmNewPassword}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleClickShowPassword(
                                        "confirmNewPassword"
                                      )
                                    }>
                                    {showPassword.confirmNewPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors.confirmNewPassword &&
                            touched.confirmNewPassword && (
                              <Typography
                                sx={{ fontSize: "9px", color: "red" }}>
                                {errors.confirmNewPassword}
                              </Typography>
                            )}
                        </Grid>
                      </Grid>

                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: "#1e40af",
                          textTransform: "none",
                          fontWeight: "bold",
                          marginTop: "3%",
                        }}
                        className="bg-blue-700 w-full"
                        size="medium"
                        type="submit">
                        {isSubmitting ? "Please wait..." : "Update Password"}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </div>
    </Box>
  );
};

export default UpdatePasswordForm;
