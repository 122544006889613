import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography, Grid } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { url } from "../../utils/Index";
import { useSelector } from "react-redux";
const PropertyListings = ({ properties, route }) => {
  const [displayCount, setDisplayCount] = useState(3);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const CarouselItems = properties?.slice(0, displayCount);
  const houses = [];
  CarouselItems?.map((property) => {
    houses.push({
      Image: property.Images[0],
      Address: property.Address,
      Price: property.Price,
      Size: property.Size,
      CreatedAt: property.CreatedAt,
      id: property._id,
    });
  });

  const handleLoadMore = () => {
    setLoading(true);
    setDisplayCount(displayCount + 3);
    setLoading(false);
  };

  return (
    <Box container component="div" className="">
      <Container>
        {loading ? (
          <Box className="flex my-5 items-center justify-center">
            <ClipLoader
              color="green"
              loading="true"
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </Box>
        ) : (
          <Box component="div" className=" mt-5 p-2 text-center">
            <Grid container spacing={2}>
              {houses.map((items, key) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={items.Name}
                    className=" flex flex-col items-center justify-center "
                    sx={{ marginTop: "3%" }}>
                    <Link
                      to={
                        user
                          ? route === "LandsListing"
                            ? `/landDetails/${items.id}`
                            : `/propertyDetails/${items.id}`
                          : "/login"
                      }>
                      <Box component="div" className="">
                        <img
                          src={items.Image}
                          alt="house Image"
                          className="rounded-xl w-72 h-48"
                        />
                        <Box component="div" className="mt-3 p-2">
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold", textAlign: "left" }}>
                            {items.Address}
                          </Typography>
                          <Box className="flex items-center justify-between">
                            <Box>
                              <Typography
                                variant="h1"
                                sx={{
                                  opacity: "0.8",
                                  fontSize: "11px",
                                  textAlign: "left",
                                }}>
                                Size
                              </Typography>
                              <Typography variant="subtitle2">
                                {items.Size}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="h1"
                                sx={{
                                  opacity: "0.8",
                                  fontSize: "11px",
                                  textAlign: "left",
                                }}>
                                Price
                              </Typography>
                              <Typography variant="subtitle2">
                                {items.Price.toLocaleString()}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>

            {displayCount < properties?.length && (
              <Button
                variant="contained"
                onClick={handleLoadMore}
                sx={{
                  bgcolor: "#1e40af",
                  textTransform: "none",
                  fontWeight: "bold",
                  marginTop: "3%",
                  marginBottom: "3%",
                }}
                className="bg-blue-500"
                size="large">
                Load More
              </Button>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default PropertyListings;
