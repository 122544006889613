import axios from "axios";
import { API_URL } from "../api";

const getSingleProperties = async (propertyId, userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_URL}/properties/${propertyId}/${userId}`,
    config
  );
  if (response.data) {
    localStorage.setItem("property", JSON.stringify(response.data));
  }
  return response.data;
};

const updateSingleProperty = async (data, propertyId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/properties/${propertyId}/update_property`,
    data,
    config
  );
  if (response.data) {
    localStorage.setItem("property", JSON.stringify(response.data));
  }
  return response.data;
};

const archiveProperty = async (propertyId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/properties/${propertyId}/archive_property`,
    "",
    config
  );
  if (response.data) {
    localStorage.setItem("property", JSON.stringify(response.data));
  }
  return response.data;
};

const unarchiveProperty = async (propertyId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/properties/${propertyId}/unarchive_property`,
    "",
    config
  );
  if (response.data) {
    localStorage.setItem("property", JSON.stringify(response.data));
  }
  return response.data;
};

const deleteProperty = async (propertyId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${API_URL}/properties/${propertyId}/delete_property`,
    config
  );
  if (response.data) {
    localStorage.setItem("property", JSON.stringify(response.data));
  }
  return response.data;
};
const authService = {
  getSingleProperties,
  updateSingleProperty,
  archiveProperty,
  unarchiveProperty,
  deleteProperty,
};

export default authService;
