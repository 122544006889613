import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-elastic-carousel";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];
const ExcitingPlaces = ({ lands }) => {
  const CarouselItems = [];

  lands?.map((property) => {
    CarouselItems.push({
      Image: property.Images[0],
      Address: property.Address,
      Price: property.Price,
      Size: property.Size,
      CreatedAt: property.CreatedAt,
      id: property._id,
    });
  });

  const { user } = useSelector((state) => state.auth);

  return (
    <Box container component="div" className=" aboutlockbg  p-2 sm:p-20 ">
      <Container>
        <Box component="div" className="text-center mt-16 sm:mt-5">
          <Typography variant="title1" sx={{ fontWeight: "bold" }}>
            Available Lands for Sale
          </Typography>
          <Typography variant="h4">
            {" "}
            <span className="text-green-600 font-bold sm:text-5xl text-md">
              Land
            </span>{" "}
            <span className="text-blue-800 font-bold sm:text-5xl text-md">
              Listing
            </span>
          </Typography>
        </Box>

        <Box component="div" className=" mt-5 p-2 text-center">
          <Carousel
            breakPoints={breakPoints}
            itemPadding={[5, 5]}
            enableAutoPlay
            autoPlaySpeed={1500}>
            {CarouselItems.map((items, key) => {
              return (
                <Link to={user ? `/landDetails/${items.id}` : "/login"}>
                  <Box
                    component="div"
                    className=" shadow-xl rounded-lg p-5 bg-gray-300">
                    <img
                      src={items.Image}
                      alt="house Image"
                      className="rounded-xl w-72 h-48"
                    />
                    <Box component="div" className="mt-3 p-2">
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold", textAlign: "left" }}>
                        {items.Address}
                      </Typography>
                      <Box className="flex items-center justify-between">
                        <Box>
                          <Typography
                            variant="h1"
                            sx={{
                              opacity: "0.8",
                              fontSize: "11px",
                              textAlign: "left",
                            }}>
                            Size
                          </Typography>
                          <Typography variant="subtitle2">
                            {items.Size}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h1"
                            sx={{
                              opacity: "0.8",
                              fontSize: "11px",
                              textAlign: "left",
                            }}>
                            Price
                          </Typography>
                          <Typography variant="subtitle2">
                            {items.Price.toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              );
            })}
          </Carousel>
          {lands?.length > 0 && (
            <Link to="findLand/LandsListing">
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#1e40af",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                className="bg-blue-500"
                size="large">
                See More
              </Button>
            </Link>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ExcitingPlaces;
