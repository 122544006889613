import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import RegisterForm from "../components/Login/RegisterForm";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";

const Register = () => {
  const location = useLocation();
  const [redirectFrom, setRedirectFrom] = useState();

  useEffect(() => {
    // Access the state from the location object
    const state = location.state;

    if (state) {
      const { redirectFrom } = state;
      setRedirectFrom(redirectFrom);
    }
  }, [location.state]);
  return (
    <>
      <Navbar />
      <RegisterForm redirectFrom={redirectFrom} />
    </>
  );
};

export default Register;
