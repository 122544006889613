import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  Typography,
  Button,
  Stack,
  Container,
  CssBaseline,
  List,
  ListItem,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useSelector, useDispatch } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
import UpdateProfileForm from "./Login/UpdateProfileForm";
import UpdatePasswordForm from "./Login/UpdatePasswordForm";
export default function DashboardNavbar() {
  const [anchorEl, setAnchorEl] = useState(false);

  const location = useLocation();
  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());

    if (user === null) {
      navigate("/");
    }
  };
  return (
    <>
      <CssBaseline />

      <Box
        sx={{
          flexGrow: 1,
          padding: 0,
          position: "sticky",
          top: 0,
          zIndex: "10",
        }}
        className="border border-gray-100 bg-white">
        <AppBar
          position="sticky"
          style={{ background: "#ffffff", margin: 0, padding: 0 }}
          elevation={0}>
          <Container>
            <Toolbar style={{ margin: 0, padding: 0 }}>
              <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                <Link to="/">
                  <span className="text-green-600 font-bold sm:text-2xl text-md">
                    Lock.
                  </span>
                  <span className="font-bold text-neutral-900 sm:text-2xl text-md">
                    your
                  </span>
                  <span className="text-blue-800 font-bold sm:text-2xl text-md">
                    .land
                  </span>
                </Link>
              </Typography>

              {/* For desktop view Ls */}
              <Box
                className="flex-row items-center sm:flex hidden"
                sx={{ width: "40%" }}>
                <ul className="flex space-x-4" sx={{ width: "80%" }}>
                  <li
                    className={`${
                      location.pathname === "/dashboard"
                        ? "text-green-600"
                        : "text-neutral-900"
                    } font-bold  `}
                    sx={{ padding: 0 }}>
                    <Link className="text-[14px]" to="/dashboard">
                      Properties
                    </Link>
                  </li>
                  {/* <li
                    className={`${
                      location.pathname === "/buyLand"
                        ? "text-green-600"
                        : "text-neutral-900"
                    } font-bold  `}
                    sx={{ padding: 0 }}>
                    <Link className="text-[14px]" to="/buyLand">
                      Buy
                    </Link>
                  </li> */}
                  <li
                    className={`${
                      location.pathname === "/sellLand"
                        ? "text-green-600"
                        : "text-neutral-900"
                    } font-bold  `}
                    sx={{ padding: 0 }}>
                    <Link className="text-[14px]" to="/sellLand">
                      Sell
                    </Link>
                  </li>
                  <li
                    className={`${
                      location.pathname === "/dashboard/verify"
                        ? "text-green-600"
                        : "text-neutral-900"
                    } font-bold  `}
                    sx={{ padding: 0 }}>
                    <Link className="text-[14px]" to="/dashboard/verify">
                      Verify{" "}
                    </Link>
                  </li>
                  <li
                    className={`${
                      location.pathname === "/escrowLand"
                        ? "text-green-600"
                        : "text-neutral-900"
                    } font-bold  `}
                    sx={{ padding: 0 }}>
                    <Link className="text-[14px]" to="/escrowLand">
                      Escrow
                    </Link>
                  </li>

                  <li
                    className={`${
                      location.pathname === "/"
                        ? "text-green-600"
                        : "text-neutral-900"
                    } font-bold  `}
                    sx={{ padding: 0 }}>
                    <Link className="text-[14px]" to="/">
                      Home
                    </Link>
                  </li>
                </ul>
                <Stack spacing={2} direction="row" className="mx-5">
                  <Button
                    onClick={handleLogout}
                    variant="contained"
                    sx={{
                      bgcolor: "#1e40af",
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                    className="bg-blue-500"
                    size="small">
                    Logout
                  </Button>
                </Stack>
              </Box>

              {/* mobile view */}
              <Box className="sm:hidden block">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{
                    bgcolor: "#000",
                    borderRadius: "15%",
                    padding: "4px 5px",
                  }}>
                  <MenuIcon />
                </IconButton>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <MenuItem>
                  <Link to="/dashboard">Properties</Link>
                </MenuItem>
                {/* <MenuItem>
                  <Link to="/buyLand">Buy Properties</Link>
                </MenuItem> */}
                <MenuItem>
                  <Link to="/sellLand">Sell Properties</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/dashboard/verify">Verify Properties</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/escrowLand">Escrow</Link>
                </MenuItem>
                <Divider />

                <MenuItem>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <Link onClick={handleLogout}>Logout</Link>
                </MenuItem>

                <MenuItem>
                  <Box className="">
                    {user?.VerifiedUser !== "completed" && (
                      <UpdateProfileForm />
                    )}

                    <UpdatePasswordForm />
                  </Box>
                </MenuItem>
              </Menu>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </>
  );
}
