import { useEffect, useState } from "react";
import { openDB } from "idb";
import { toast } from "react-toastify";

const DB_NAME = "LandFormDrafts";
const STORE_NAME = "drafts";

const useSaveDraft = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [lastSavedDate, setLastSavedDate] = useState(null);
  const [currentDraftId, setCurrentDraftId] = useState(null);
  const [drafts, setDrafts] = useState([]);
  const [draft, setDraft] = useState([]);

  useEffect(() => {
    initDB();
  }, []);

  const initDB = async () => {
    try {
      const db = await openDB(DB_NAME, 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME, {
              keyPath: "draftId",
              autoIncrement: true,
            });
          } else {
          }
        },
      });
    } catch (error) {}
  };

  const saveDraft = async (formValues, draftName) => {
    setIsSaving(true);
    try {
      const db = await openDB(DB_NAME, 1);
      const tx = db.transaction(STORE_NAME, "readwrite");
      const store = tx.objectStore(STORE_NAME);

      // const serializedValues = serializeFormData(formValues);
      const savedDate = new Date();

      const draftData = {
        name: draftName,
        values: formValues,
        lastSaved: savedDate,
      };

      if (currentDraftId) {
        // Update existing draft
        draftData.draftId = currentDraftId;
        await store.put(draftData);
      } else {
        // Create new draft
        const newDraftId = await store.add(draftData);
        setCurrentDraftId(newDraftId);
      }

      await tx.done; // Ensure transaction is completed
      setLastSavedDate(savedDate);
      toast.success(
        `Draft ${currentDraftId ? "updated" : "saved"} successfully`
      );
      return currentDraftId;
    } catch (error) {
      toast.error("Error saving draft:", error);
      throw error;
    } finally {
      setIsSaving(false);
    }
  };

  const loadDraft = async (draftId) => {
    try {
      const db = await openDB(DB_NAME, 1);
      const tx = db.transaction(STORE_NAME, "readonly");
      const store = tx.objectStore(STORE_NAME);
      const numericId = parseInt(draftId, 10);
      const draftDb = await store.get(numericId);
      await tx.done;

      if (draftDb) {
        setCurrentDraftId(draftId);
        setDraft(draftDb.values);
        return draftDb.values;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const loadAllDrafts = async () => {
    setIsSaving(true);
    try {
      const db = await openDB(DB_NAME, 1);
      const tx = db.transaction(STORE_NAME, "readonly");
      const store = tx.objectStore(STORE_NAME);

      const allDrafts = await store.getAll();
      await tx.done;
      setIsSaving(false);
      setDrafts(allDrafts);

      return allDrafts; // Return the loaded drafts
    } catch (error) {
      setIsSaving(false);

      return []; // Return an empty array in case of an error
    }
  };

  const deleteDraft = async (draftId) => {
    try {
      const db = await openDB(DB_NAME, 1);
      const tx = db.transaction(STORE_NAME, "readwrite");
      const store = tx.objectStore(STORE_NAME);
      const numericId = parseInt(draftId, 10);
      await store.delete(numericId);
      await tx.done;

      if (draftId === currentDraftId) {
        setCurrentDraftId(null);
        setLastSavedDate(null);
      }
    } catch (error) {}
  };

  const listDrafts = async () => {
    try {
      const db = await openDB(DB_NAME, 1);
      const tx = db.transaction(STORE_NAME, "readonly");
      const store = tx.objectStore(STORE_NAME);

      const drafts = await store.getAll();
      await tx.done;

      return drafts;
    } catch (error) {
      return [];
    }
  };

  const createNewDraft = () => {
    setCurrentDraftId(null);
    setLastSavedDate(null);
  };

  return {
    saveDraft,
    loadDraft,
    deleteDraft,
    listDrafts,
    createNewDraft,
    loadAllDrafts,
    isSaving,
    isLoading: isSaving,
    drafts,
    draft,
    lastSavedDate,
    currentDraftId,
  };
};

export default useSaveDraft;
